import Vue from 'vue'
import Router from 'vue-router'
import { GeoGlobals } from './GeoGlobals'
declare var $geoGlobals: GeoGlobals

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'start',
      redirect: { name: 'intern' }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/recover/:guid',
      name: 'recover',
      component: () => import('./views/Recover.vue'),
    },
    {
      path: '/i',
      name: 'intern',
      redirect: { name: 'sprlist' },
      component: () => import('./views/Intern.vue'),
      children: [
        {
          path: 'list',
          name: 'sprlist',
          component: () => import('./views/Liste.vue')
        }, {
          path: 'spr/:id',
          component: () => import('./views/Details.vue'),
          name: 'sprdetails',
        }, {
          path: 'profile',
          name: 'userprofile',
          component: () => import('./views/Profile.vue')
        }, {
          path: 'search',
          name: 'search',
          component: () => import('./views/Suche.vue')
        }, {
          path: 'people',
          name: 'people',
          component: () => import('./views/AdminPeople.vue')
        }
        , {
          path: 'products',
          name: 'products',
          component: () => import('./views/AdminProducts.vue')
        }
        , {
          path: 'groups',
          name: 'groups',
          component: () => import('./views/AdminGroups.vue')
        }
        , {
          path: 'config',
          name: 'config',
          component: () => import('./views/Config.vue')
        }


      ],
      beforeEnter: (to, from, next) => {

        if (!$geoGlobals.jwtoken) {
          $geoGlobals.destination = to.path
          next("/login")
        } else {
          next()
        }
      }
    },
    {
      path: '/impressum',
      name: 'impressum',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/Impressum.vue'),
    },
    {
      path: '/datenschutz',
      name: 'datenschutz',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/Datenschutz.vue'),
    },
    {
      path: '*',
      redirect: { name: 'intern' },
    }
  ],
})