





























import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals

import Vue from "vue"
const jwt = require("jsonwebtoken")
const sha256 = require("simple-sha256")
const md5 = require("md5")

export default Vue.extend({
  name: "login-form",
  mounted() {
    this.refreshToken(
      (result) => {
        this.$event.$emit("update-logout-button")
        if ($geoGlobals.destination) {
          this.$router.push($geoGlobals.destination)
          $geoGlobals.destination = null
        } else {
          this.$router.push("i")
        }
      },
      (error) => {
        console.error("silent login failed, why?", error)
        this.autoFailed = true
      }
    )
  },
  data() {
    return {
      autoFailed: false,
      show1: false,
      password: "",
      username: "",
      rules: {
        required: value => !!value || "Eingabe notwendig."
      }
    }
  },
  methods: {
    getToken(mode: "refresh" | "login", success: Function, failure: Function) {

      const aufruf = mode == "refresh" ? "RefreshToken" : "Login"
      let post
      if (mode == "refresh") {
        console.info("refreshing token")
        post = this.$http.post($geoGlobals.ServerAddress + "RefreshToken", {
          responseType: "json"
        })
      } else if (mode == "login") {
        const postData = {
          login: this.username,
          sha256PwdHash: sha256.sync(this.password),
          md5PwdHash: md5(this.password)
        }
        post = this.$http.post($geoGlobals.ServerAddress + "Login", postData)
      }

      post.then(
        result => {

          const token: string = result.data.d.Message

          try {
            const decoded = jwt.decode(token)
            if (decoded != null) {
              const token_lifetime = decoded.LifeTimeSeconds / 2
              clearTimeout($geoGlobals.refreshTimer)
              $geoGlobals.refreshTimer = setTimeout(
                this.refreshToken,
                token_lifetime * 1000,
                () => {
                  if (process.env.NODE_ENV === 'development') {
                    this.$event.$emit(
                      "global-snackbar",
                      "Token-Refresh erfolgreich(DEBUG)"
                    )
                  }
                },
                () => {
                  this.$event.$emit(
                    "global-snackbar",
                    "Token-Refresh fehlgeschlagen"
                  )
                  console.error("refresh failed4")
                }
              )
              $geoGlobals.jwtoken = token
              success()
            } else {
              failure(token)
            }
          } catch (err) {
            console.error("refresh failed", err)
            failure()
          }
        },
        error => {
          console.error("Err", error)
          failure()
        }
      )
    },

    refreshToken: function (success: () => void, failure: () => void) {
      /*if ($geoGlobals.jwtoken == null) {
        failure()
        return
      }*/
      this.getToken("refresh", success, failure)
    },
    attempLogin: function () {
      if (!this.$refs.form.validate()) {
        this.$event.$emit("global-snackbar", "Formulareingaben nicht gültig")
        return
      }

      this.getToken(
        "login",
        (result) => {
          this.$event.$emit("global-snackbar", "Anmeldedaten korrekt")
          this.$event.$emit("update-logout-button")

          if ($geoGlobals.destination != null) {
            this.$router.push($geoGlobals.destination)
            $geoGlobals.destination = null
          } else {
            this.$router.push("i")
          }
        },
        (error) => {
          if (error == undefined) {
            error = "Der Server ist nicht erreichbar"
          }
          this.$event.$emit("global-snackbar", error)
          console.error("getToken()", error)
        }
      )
    }
  }
});
