



















































































































































import Vue from "vue"
import {
  ConflictResolutionType,
  DtoResultState,
  DtoState,
} from "../FacadeVorgangListe"
import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
import Config from "../Config"
const config = new Config()

var cloneDeep = require("lodash.clonedeep")

export default Vue.extend({
  mounted() {
    this.GetList()
  },
  computed: {
    visibleZustaende: function () {
      let rv = this.tempVersion.Zustaende
      rv = rv.filter((a) => {
        return (a.State & DtoState.Removed) == 0
      })
      return rv
    },
    visibleVersionen: function () {
      let rv = this.facadeProdukt.Produkt.Produktversionen
      rv = rv.filter((a) => {
        return (a.State & DtoState.Removed) == 0
      })
      return rv
    },
    ZustandStatus: function () {
      return config.data.ZustandStatus
    },
    groupDisabled: function () {
      return (this.facadeProdukt.Produkt.State & DtoState.New) == 0
    },
    newProduktDisabled: function () {
      return !((this.facade.Produkte.State & DtoState.Add) == DtoState.Add)
    },
    newVersionDisabled: function () {
      let rv = !(
        (this.facadeProdukt.Produkt.State & DtoState.Edit) ==
        DtoState.Edit
      )
      return rv
    },
    newZustandDisabled: function () {
      let rv = !(
        (this.facadeProdukt.Produkt.State & DtoState.Edit) ==
        DtoState.Edit
      )
      return rv
    },
    showDialogVersionEditieren: function () {
      return this.tempVersion != null
    },
    showDialogZustandEditieren: function () {
      return this.tempZustand != null
    },
    showDialogProduktEditieren: function () {
      return this.facadeProdukt != null && this.facadeProduktDialog != null
    },
  },
  methods: {
    zustandDeletable: function (state: number) {
      if ((state & DtoState.Remove) == DtoState.Remove) {
        return true
      }

      if ((state & DtoState.New) == DtoState.New) {
        return true
      }

      return false
    },

    StoreProdukt: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ProduktDialogSpeichern",
        {
          dtoProduktDlg: this.facadeProdukt.Produkt,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )

      post.then(
        (result) => {
          result = result.data.d

          if (result.Status == DtoResultState.Ok) {
            this.facadeProdukt = null
            this.GetList()
          } else {
            console.error("StoreProdukt()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("StoreProdukt()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    StoreVersion: function () {
      // Validate
      if (this.tempVersion.Zustaende.length == 0) {
        this.$event.$emit(
          "global-snackbar",
          "Eine Version benötigt mindestens einen Zustand."
        )
        return
      }

      if (this.selectedZustand.length == 0) {
        this.$event.$emit(
          "global-snackbar",
          "Eine Version muss einen ausgewählten, aktiven Zustand haben."
        )
        return
      }

      // Noch nicht speichern, d. h.:
      // tempVersion in tempProdukt
      this.tempVersion.AktuellerZustandRef.ShortGuid = this.selectedZustand[0]?.ShortGuid
      // Update or Add tempZustand
      const id = this.tempVersion.ShortGuid
      const versionen = this.facadeProdukt.Produkt.Produktversionen

      let found = versionen.find((a) => {
        return a.ShortGuid == id
      })
      if (found == null) {
        this.facadeProdukt.Produkt.Produktversionen.push(this.tempVersion)
        this.tempVersion = null
      } else {
        this.facadeProdukt.Produkt.Produktversionen = this.facadeProdukt.Produkt.Produktversionen.filter(
          (item) => item !== found
        )
        this.facadeProdukt.Produkt.Produktversionen.push(this.tempVersion)
        this.tempVersion = null
      }
    },
    StoreZustand: function () {
      // Update or Add tempZustand
      const id = this.tempZustand.ShortGuid

      if (this.tempVersion.Zustaende == null) {
        this.tempVersion.Zustaende = []
      }
      const zustaende = this.tempVersion.Zustaende
      let found = zustaende.find((a) => {
        return a.ShortGuid == id
      })

      if (found == null) {
        // TODO Letzten Zustand wegen Reihenfolge ermitteln
        let max = Math.max.apply(
          Math,
          this.tempVersion.Zustaende.map(function (o) {
            if (o.Sort == null) return -1
            return o.Sort
          })
        )
        this.tempZustand.Sort = max + 1
        if (this.tempZustand.Sort < 0) this.tempZustand.Sort = 0
        this.tempVersion.Zustaende.push(this.tempZustand)
        this.tempZustand = null
      } else {
        this.tempVersion.Zustaende = this.tempVersion.Zustaende.filter(
          (item) => item !== found
        )
        this.tempVersion.Zustaende.push(this.tempZustand)
        this.tempZustand = null
      }
    },
    updateZustand: function () {
      this.tempZustand.Status = this.selectedZustandStatus
      const zs = this.ZustandStatus.find((a) => {
        return a.ShortId == this.selectedZustandStatus
      })

      if (this.tempZustand.Name == null || this.tempZustand.Name.length == 0) {
        this.tempZustand.Name = zs.Name
      }
      if (
        this.tempZustand.Beschreibung == null ||
        this.tempZustand.Beschreibung.length == 0
      ) {
        this.tempZustand.Beschreibung = zs.Beschreibung
      }
      // this.tempZustand.Status = this.selectedZustandStatus.ShortId
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == "Name") {
          const aParts = a.Name.split(/[\.\ ]/gm)
          const bParts = b.Name.split(/[\.\ ]/gm)
          const maxParts = Math.max(aParts.length, bParts.length)
          let rv: number
          for (let i = 0; i < maxParts; i++) {
            const aPart = aParts[i]
            const bPart = bParts[i]
            if (aPart != null && bPart != null) {
              if (!isDesc[0]) {
                rv = aPart.localeCompare(bPart)
              } else {
                rv = bPart.localeCompare(aPart)
              }
              if (rv != 0) return rv
            } else {
              if (aPart == undefined) rv = -1
              else rv = +1
              if (isDesc[0]) rv = rv * -1
              return rv
            }
          }
        } else if (index == "AktuellerZustandRef.ShortGuid") {
          const asg = a.AktuellerZustandRef.ShortGuid
          const bsg = b.AktuellerZustandRef.ShortGuid
          a = this.ToZustand(a.ShortGuid, asg)
          b = this.ToZustand(b.ShortGuid, bsg)
          if (!isDesc[0]) {
            return a < b ? -1 : 1
          } else {
            return b < a ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    ToZustand: function (versionId: string, zustandId: string) {
      let v = this.facadeProdukt.Produkt.Produktversionen.find((a) => {
        return a.ShortGuid == versionId
      })
      let z = v.Zustaende.find((a) => {
        return a.ShortGuid == zustandId
      })

      if (z == null) return zustandId
      return z.Name
    },
    clickRemoveZustand(zustandId: string) {
      let z = this.tempVersion.Zustaende.find((a) => {
        return a.ShortGuid == zustandId
      })
      this.tempVersion.Zustaende = this.tempVersion.Zustaende.filter(
        (item) => item !== z
      )
    },

    clickDeleteVersion(versionId: string) {
      let v = this.facadeProdukt.Produkt.Produktversionen.find(
        (a) => a.ShortGuid == versionId
      )
      v.State = v.State | DtoState.Removed
      v.Zustaende.forEach((z) => {
        v.State = z.State | DtoState.Removed
      })
    },
    clickDeleteZustand(versionId: string) {
      let z = this.tempVersion.Zustaende.find((a) => a.ShortGuid == versionId)
      z.State = z.State | DtoState.Removed
    },
    clickEditZustand: function (zustandId: string) {
      this.titleZustand = "Zustand editieren"
      let z = this.tempVersion.Zustaende.find((a) => {
        return a.ShortGuid == zustandId
      })
      this.tempZustand = cloneDeep(z)

      this.selectedZustandStatus = this.tempZustand.Status
    },
    clickNewZustand: function () {
      this.titleZustand = "Zustand hinzufügen"
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ZustandNeu",
        {},
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.tempZustand = result.Zustand
            this.selectedZustandStatus = null
          } else {
            console.error("clickNewZustand()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("clicknewZustand()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    clickDeleteProdukt: function (shortGuid: string) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ProduktLoeschen",
        {
          dtoProduktRef: { ShortGuid: shortGuid },
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )
      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.GetList()
          } else {
            console.error("ProduktLoeschen", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("ProduktLoeschen", error)
        }
      )
    },
    clickNewProdukt: function () {
      this.clickEditProdukt(null)
    },

    clickNewVersion: function () {
      this.titleVersion = "Version hinzufügen"
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ProduktversionNeu",
        {},
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            if (result.Produktversion.Zustaende == null) {
              result.Produktversion.Zustaende = []
            }

            this.tempVersion = result.Produktversion
          } else {
            console.error("clickNewVersion()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("clicknewVersion()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    clickEditVersion: function (id: string) {
      this.titleVersion = "Version editieren"
      let v = this.facadeProdukt.Produkt.Produktversionen.find((a) => {
        return a.ShortGuid == id
      })

      // aktuellen Zustand setzen
      let z = v.Zustaende.find((a) => {
        return a.ShortGuid == v.AktuellerZustandRef.ShortGuid
      })
      this.selectedZustand = [z]
      this.tempVersion = cloneDeep(v)
    },
    clickEditProdukt: function (id: string | null) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      // Nicht Produktabhängig, also nicht immer neu laden
      if (this.facadeProduktDialog == null) {
        let postDialog = this.$http.post(
          $geoGlobals.ServerAddress + "ProduktDialogLaden",
          {},
          auth
        )
        postDialog.then(
          (result) => {
            if (result.data.d.Status == DtoResultState.Ok) {
              result = result.data.d
              this.facadeProduktDialog = result
            }
          },
          (error) => { }
        )
      }

      let postProdukt: any
      if (id != null) {
        this.titleProdukt = "Produkt editieren"
        let produkt1: any = {}
        produkt1.ShortGuid = id
        produkt1.State = DtoState.None

        postProdukt = this.$http.post(
          $geoGlobals.ServerAddress + "ProduktLaden",
          { dtoProduktRef: produkt1 },
          auth
        )
      } else {
        this.titleProdukt = "Produkt hinzufügen"
        postProdukt = this.$http.post(
          $geoGlobals.ServerAddress + "ProduktNeu",
          {},
          auth
        )
      }

      postProdukt.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            result = result.data.d
            this.facadeProdukt = result
          } else {
            console.error("clickEditProdukt()", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        (error) => {
          console.error("clickEditProdukt()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    GetList: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ProdukteVerwaltenDialogLaden",
        {},
        auth
      )

      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.facade = result.data.d
          } else {
            console.error("GetList()", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        (error) => {
          console.error("GetList()", error)
        }
      )
    },
  },

  watch: {
    "tempVersion.Zustaende": {
      handler(val, oldV) {
        if (val === undefined) return
        if (val.length == 1) {
          this.selectedZustand = []
          this.selectedZustand.push(val[0])
        }
      },
    },
  },
  data() {
    return {
      titleProdukt: "",
      titleVersion: "Hallo",
      titleZustand: "",
      facade: null,
      selectedZustand: [],
      selectedZustandStatus: null,
      tempVersion: null,
      tempZustand: null,
      facadeProdukt: null,
      facadeProduktDialog: null,
      zustandHeaders: [
        {
          text: "Zustand",
          value: "Name",
          sortable: false,
        },
        {
          text: "Beschreibung",
          value: "Beschreibung",
          sortable: false,
        },
        {
          text: "Aktionen",
          value: "ShortGuid",
          sortable: false,
          width: "10px",
        },
      ],
      versionHeaders: [
        {
          text: "Version",
          value: "Name",
        },
        {
          text: "Aktueller Zustand",
          value: "AktuellerZustandRef.ShortGuid",
          sortable: true,
        },
        {
          text: "Beschreibung",
          value: "Beschreibung",
        },
        {
          text: "Aktionen",
          value: "ShortGuid",
          sortable: false,
          width: "10px",
        },
      ],
      produkteHeaders: [
        {
          text: "ShortGuid",
          align: " d-none",
          value: "ShortGuid",
        },
        {
          text: "Produkt",
          align: "start",
          value: "Name",
          sortable: true,
        },
        {
          text: "Produktgruppe",
          align: "start",
          value: "Gruppenname",
          sortable: true,
        },
        {
          text: "Aktionen",
          value: "ShortGuid",
          sortable: false,
          width: "10px",
        },
      ],
    }
  },
})
