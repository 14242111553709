


































var jwt = require("jsonwebtoken")
import LoginForm from '../components/LoginForm.vue'
import { GeoGlobals } from "../GeoGlobals"
import Vue from 'vue'
declare const $geoGlobals: GeoGlobals

export default Vue.extend({
  name: 'home',
  components: {
    LoginForm,
  },
  data() {
    return {
      user: "",
      rules: {
        required: value => !!value || "Eingabe notwendig."
      }
    }
  },
  methods: {
    RequestRecoveryEmail: function () {
      if (!this.$refs.form.validate()) {
        this.$event.$emit("global-snackbar", "Formulareingaben nicht gültig")
        return
      }
      const postData = {
        user: this.user,
        location: window.location.href

      }
      let post = this.$http.post($geoGlobals.ServerAddress + "SendPasswordRecoveryEmail", postData)
      post.then(result => {
        result = result.data.d
        this.$event.$emit("global-snackbar", result.Message)
      }, error => {
        console.error("SendPasswordRecoveryEmail", error)
      })
    }
  }
})
