
























































































































































































import Vue from "vue"
import PersonenControl from "../components/PersonenControl.vue"
import PersonNeuEdit from "../components/PersonNeuEdit.vue"
import {
  ConflictResolutionType,
  DtoResultState,
  DtoState,
} from "../FacadeVorgangListe"
import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
var cloneDeep = require("lodash.clonedeep")
import Config from "../Config"
const config = new Config()

export default Vue.extend({
  components: {
    PersonenControl,
    PersonNeuEdit,
  },
  destroyed() {
    this.$event.$off("edit-person")
    this.$event.$off("delete-person")
    this.$event.$off("new-person")
    this.$event.$off("edit-roles")
  },
  mounted() {
    this.$event.$on("edit-person", (data) => {
      this.GetReadyForEditingPerson(data)
    })

    this.$event.$on("new-person", () => {
      this.GetReadyForNewPerson()
    })

    this.$event.$on("delete-person", (data) => {
      this.DeletePerson(data)
    })
    this.$event.$on("edit-roles", (data) => {
      this.GetReadyForEditingRoles(data)
    })
    this.GetList()
  },
  computed: {
    showDialogPersonEditieren: function () {
      return this.tempMelderFacade != null && this.tempMelderPerson != null
    },
    showDialogRollenZuordnen: function () {
      return this.rollenFacade != null
    },
  },
  methods: {
    UpdateSaveDisabled: function () {
      this.rollenFacade.PRegistrierungen.forEach((preg) => {
        preg.selected.sort()
      })
      let loadedRollen = JSON.parse(this.rollenAsLoaded)
      loadedRollen.PRegistrierungen.forEach((preg) => {
        preg.selected.sort()
      })

      const aaa = JSON.stringify(this.rollenFacade)
      const bbb = JSON.stringify(loadedRollen)
      this.saveDisabled = aaa == bbb
      // Das waren die PRegistrierungen, jetzt auch die Rollen
      if (this.saveDisabled) {
        let c = cloneDeep(this.rollenSelected).sort()
        let rollenSelectedAsNow = JSON.stringify(c)
        this.saveDisabled = rollenSelectedAsNow == this.rollenSelectedAsLoaded
      }
    },
    StorePerson: function () {
      const p = this.tempMelderPerson.Person
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlSpeichern",
        {
          dtoPerson: p,
          /*dtoInstitutionen: this.facade.Institutionen,*/
          dtoInstitutionen: this.tempMelderFacade.Institutionen,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.$event.$emit(
              "global-snackbar",
              result.Message || "Speichern erfolgreich"
            )
            this.tempMelderPerson = null
            this.GetList()
          } else {
            console.error(
              "PersonControlSpeichern",
              result.Message || "Speichern fehlgeschlagen"
            )
            this.$event.$emit(
              "global-snackbar",
              result.Message || "Speichern fehlgeschlagen"
            )
          }
        },
        (error) => {
          console.error("PersonControlSpeichern", error)
          this.$event.$emit("global-snackbar", error)
        }
      )

      this.showDialogNeuerMelder = false
    },
    updateSorting() {
      this.UpdateSaveDisabled()
      this.rollenFacade.PRegistrierungen.forEach((element) => {
        element.selected.sort((v1, v2) => {
          return config.getFolgestatus(v1)[0] > config.getFolgestatus(v2)[0]
        })
      })
    },
    ToKuerzel(item) {
      if (item.Kuerzel != null && item.Kuerzel.length > 0) return item.Kuerzel
      return item.Name
    },
    ToBeide(item) {
      if (item.Kuerzel == null) return item.Name
      return item.Name + " (" + item.Kuerzel + ")"
    },

    allFolgestatus(id: string): [] {
      let v = config.data.Folgestatus.filter((el) => {
        return el.GruppeRef.ShortGuid === id
      })

      v.forEach((element) => {
        if (element.Kuerzel != null && element.Kuerzel.length > 0) {
          element.Langname = element.Name + " (" + element.Kuerzel + ")"
        }
      })

      v.sort((v1, v2) => {
        return v1.Name > v2.Name
      })
      return v
    },
    ToFolgestatus(id: string): string {
      const config = new Config()
      let v = config.data.Folgestatus.find((el) => {
        return el.ShortGuid == id
      })
      //return id
      return v.Name
    },
    StoreRollen: function (close: boolean) {
      let person1: any = {}
      person1.ShortGuid = this.rollenFacade.AnzeigePerson.ShortGuid
      person1.State = DtoState.None

      this.rollenSelected.forEach((rolle) => {
        rolle.Zugeordnet = true
      })

      // Ausgewählte ShortGuids in Referenzen verwandeln
      let allStatus = this.rollenFacade.PRegistrierungen
      allStatus.forEach((preg) => {
        preg.CfgFolgestatusRefs = []
        preg.selected.forEach((id) => {
          let neu = {
            ShortGuid: id,
            State: 0,
          }
          preg.CfgFolgestatusRefs.push(neu)
        })
      })

      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonDialogSpeichern",
        {
          dtoPersonRef: person1,
          dtoListeRollen: this.rollenSelected,
          dtoPRegistrierungen: this.rollenFacade.PRegistrierungen,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            if (close) this.rollenFacade = null
            else {
              this.GetReadyForEditingRoles(
                this.rollenFacade.AnzeigePerson.ShortGuid
              )
            }
          } else {
            console.error("PersonDialogSpeichern", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("PersonDialogSpeichern", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },

    GetList: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonenVerwaltenDialogLaden",
        {},
        auth
      )
      post.then(
        (result) => {
          this.facade = result.data.d
          this.facade.Institutionen.forEach((element) => {
            if (element.BundeslandRef == null) element.BundeslandRef = {}
          })
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("PersonenVerwaltenDialogLaden", error)
        }
      )
    },

    GetReadyForEditingRoles: function (guid) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonDialogLaden",
        {
          dtoPersonRef: { ShortGuid: guid, State: DtoState.None },
        },
        auth
      )

      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.rollenFacade = result.data.d
            let allRollen = this.rollenFacade.Rollen
            this.rollenSelected = []
            allRollen.forEach((rolle) => {
              rolle.disabled = !(
                (rolle.State & DtoState.Edit) ==
                DtoState.Edit
              )
              if (rolle.Zugeordnet == true) {
                this.rollenSelected.push(rolle)
              }
            })
            this.rollenSelectedAsLoaded = JSON.stringify(
              this.rollenSelected.sort()
            )
            let allStatus = this.rollenFacade.PRegistrierungen
            allStatus.forEach((preg) => {
              preg.selected = []
              preg.disabled = !((preg.State & DtoState.Edit) == DtoState.Edit)
              preg.CfgFolgestatusRefs.forEach((st) => {
                preg.selected.push(st.ShortGuid)
              })
            })
            this.rollenAsLoaded = JSON.stringify(this.rollenFacade)
            this.$nextTick(() => {
              this.updateSorting()
            })
          } else {
            console.error("PersonDialogLaden", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        (error) => {
          console.error("PersonDialogLaden", error)
        }
      )
    },
    DeletePerson: function (guid) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonLoeschen",
        {
          dtoPersonRef: { ShortGuid: guid },
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )
      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.GetList()
          } else {
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("error", error)
        }
      )
    },
    GetReadyForNewPerson: function () {
      this.personNeu = 1
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlLaden",
        {},
        auth
      )
      post.then(
        (result) => {
          this.tempMelderFacade = result.data.d
          this.tempMelderFacade.Institutionen.forEach((element) => {
            if (element.BundeslandRef == null) element.BundeslandRef = {}
          })
        },
        (error) => {
          console.error(error)
        }
      )

      post = this.$http.post($geoGlobals.ServerAddress + "PersonNeu", {}, auth)
      post.then(
        (result) => {
          result = result.data.d

          this.tempMelderPerson = result
          if (this.tempMelderPerson.Person.InstitutionRef == null) {
            this.tempMelderPerson.Person.InstitutionRef = {}
          }
        },
        (error) => {
          console.error(error)
        }
      )
    },
    GetReadyForEditingPerson: function (guid) {
      this.personNeu = 2
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlLaden",
        {},
        auth
      )
      post.then(
        (result) => {
          this.tempMelderFacade = result.data.d
          this.tempMelderFacade.Institutionen.forEach((element) => {
            if (element.BundeslandRef == null) element.BundeslandRef = {}
          })
        },
        (error) => {
          console.error(error)
        }
      )

      post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonLaden",
        {
          dtoPersonRef: { ShortGuid: guid },
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )
      post.then(
        (result) => {
          result = result.data.d

          this.tempMelderPerson = result
          if (this.tempMelderPerson.Person.InstitutionRef == null) {
            this.tempMelderPerson.Person.InstitutionRef = {}
          }
          this.personAsLoaded = JSON.stringify(this.tempMelderPerson)
        },
        (error) => {
          console.error("ERROR", error)
        }
      )
    },
  },

  watch: {
    rollenSelected: {
      handler(newV, oldV) {
        this.UpdateSaveDisabled()
        /*const currentConfig = JSON.stringify(this.facade.Config);
        this.$event.$emit(
          "page-modified-changed",
          !(currentConfig === this.configAsLoaded)
        );*/
      },
      deep: true,
    },
  },

  data() {
    return {
      saveDisabled: true,
      personNeu: 1,
      rollenAsLoaded: "",
      title: "Person editieren",
      facade: null,
      rollenSearch: "",
      rollenFacade: null,
      tempMelderFacade: null,
      tempMelderPerson: null,
      rollenSelected: [],
      registrierungenHeaders: [
        {
          text: "State",
          align: " d-none",
          value: "ShortGuid",
        },
        {
          text: "Produkt",
          align: "start",
          value: "Name",
          sortable: true,
        },
        {
          text: "Produktgruppe",
          align: "start",
          value: "Gruppenname",
          sortable: true,
        },
        {
          text: "Folgestatus",
          align: "start",
          value: "selected",
          sortable: false,
        },
        /*
        {
          text: "Aktion",
          value: "ShortGuid",
          sortable: false,
          width: "10px",
        }*/
      ],
      rollenHeaders: [
        {
          text: "State",
          align: " d-none",
          value: "ShortGuid",
        },
        {
          text: "Rolle",
          align: "start",
          value: "Name",
          sortable: true,
        },
        {
          text: "Berechtigungen",
          align: "start",
          value: "RechteSatzInfo",
          sortable: false,
        },
      ],
    }
  },
});
