import { GeoGlobals } from "./GeoGlobals"

declare const $geoGlobals: GeoGlobals

export default class Config {
    data: any
    private data2: any
    constructor() {
        if ($geoGlobals.configData) {
            this.data = $geoGlobals.configData
        } else if (sessionStorage.getItem("config") !== null) {
            $geoGlobals.configData = JSON.parse(sessionStorage.getItem("config")).Config
            this.data = $geoGlobals.configData
        } else {
            console.error("constructor()", "problem getting config data")
        }
    }
    get MaxFileSize(): number {

        const outerConfig = JSON.parse(sessionStorage.getItem("config"))
        return outerConfig.MaxUploadFileSize
    }

    private getDefaultConfig(shortId: number, entries: any[]): [string, string, string | undefined] {
        const entry = entries.find((e) => {
            return e.ShortId == shortId
        })
        if (entry === undefined) {
            return [shortId.toString(), shortId.toString(), undefined]
        }
        const name = entry.Name
        const kurz = entry.Kuerzel ?? entry.Name
        const lang = entry.Beschreibung ?? undefined
        return [kurz, name, lang]
    }

    public getVorgangPriorität(value: number): [string, string, string | undefined] {
        const entries: any[] = this.data.VorgangPrios
        return this.getDefaultConfig(value, entries)
    }

    public getAktion(value: string): [string, string, string, string | undefined, string] {
        let entries: any[] = this.data.Aktionen
        entries = entries.filter(el => { return el.Klasse == value })
        if (entries.length == 0) {
            entries = this.data.Aktionen.filter(el => {
                return el.ShortGuid == value
            })
        }
        const entry = entries[0]
        const kurz = entry.Kuerzel ?? entry.AdjektivName
        const adjektiv = entry.AdjektivName
        const infinitiv = entry.VerbName
        const lang = entry.Beschreibung ?? undefined
        let shortClass = entry.Klasse
        shortClass = shortClass.substr(shortClass.lastIndexOf(".") + 1)
        return [kurz, adjektiv, infinitiv, lang, shortClass]

    }

    public getVorgangTyp(value: number): [string, string, string | undefined] {
        const entries: any[] = this.data.VorgangTypen
        return this.getDefaultConfig(value, entries)
    }

    public getVorgangStatus(value: number): [string, string, string | undefined] {
        const entries: any[] = this.data.VorgangStatus
        return this.getDefaultConfig(value, entries)
    }

    public getFolgestatus(id: string): [string, string, string | undefined] {
        const entries: any[] = this.data.Folgestatus
        let treffer = entries.find((e) => {
            return e.ShortGuid == id
        })
        const name = treffer.Name
        const kurz = treffer.Kuerzel ?? treffer.Name
        const lang = treffer.Beschreibung ?? undefined
        return [kurz, name, lang]
    }

    public getPerson(id: string): [string, string, string] {
        const entries: any[] = this.data.Personen
        const entry = entries.find((e) => {
            return e.Id == id
        })
        if (entry === undefined) {
            return [id, id, id]
        }
        const vorname = entry.Vor
        const nachname = entry.Nach
        const kurz = entry.Kurz ?? entry.Vor[0] + ". " + entry.Nach
        return [vorname, nachname, kurz]

    }
}


