





















































































































































































































































































































































import PersonNeuEdit from "../components/PersonNeuEdit.vue"
import ListeControl from "./ListeControl.vue"
import Config from "../Config"
const config = new Config()
import { GeoGlobals } from "../GeoGlobals"
import {
  DtoState,
  ConflictResolutionType,
  DtoResultState,
} from "../FacadeVorgangListe"

import { type } from "os"
declare const $geoGlobals: GeoGlobals
export default {
  components: {
    ListeControl,
    PersonNeuEdit,
  },
  props: ["aktion", "facade", "aktionFacade", "vorgang", "erlaubteAktionen"],

  destroyed() {
    this.$event.$off("produktChanged")
  },

  created() {
    if (this.aktion.produkt) {
      this.produkt = this.aktion.produkt
      delete this.aktion.produkt
    }
  },

  mounted() {
    this.$event.$on("produktChanged", (data) => {
      if (data != this.produkt) {
        this.produkt = data
      }
    })

    this.$nextTick(() => {
      this.resetModified()
    })
    $geoGlobals.showAddImage = (editor) => {
      this.selectedFile = null
      this.imageDialog = true
      this.imageEditor = editor
    }
  },
  computed: {
    klonProdukte: function () {
      // Get the Produktgruppe of the current Produkt
      let p = this.facade.Produkte.find((a) => a.ShortGuid == this.produkt)
      this.produktgruppe = p.GruppeRef.ShortGuid
      // Everything else as in SprHeader
      let values: [] = this.facade.Produkte
      let ffilter = this.produktgruppe
      if (ffilter == null) return values
      let rvv = values.filter((el: any) => {
        return el.GruppeRef.ShortGuid == ffilter
      })

      let fitting = rvv.filter((el: any) => {
        return el.ShortGuid == this.produkt
      })
      if (fitting.length == 0) {
        this.produkt = null
        this.$event.$emit("produktChanged", null)
      }
      return rvv
    },
    isDeleted: function () {
      return (this.aktion.State & DtoState.Removed) == DtoState.Removed
    },
    /*
          _value : {
            get : function () {
              return this.aktion.Beschreibung
            },
            set : function(val) {
              this.$emit('input', val)
            }
        },   */
    sortedBundeslaender: function () {
      var rv = this.facade.Bundeslaender
      rv.sort((v1, v2) => {
        return v1.Name.localeCompare(v2.Name)
      })
      return rv
    },
    readableWiedervorlage() {
      if (this.aktion.Wiedervorlage == null) return "(kein Wiedervorlagedatum)"
      if (typeof this.aktion.Wiedervorlage == "string") {
        let v = new Date(this.aktion.Wiedervorlage)
        return this.ToDateText(v)
      }

      return this.ToDateText(this.aktion.Wiedervorlage)
    },
    allowedAction() {
      if (!this.justCreated) return true
      if (this.erlaubteAktionenNamen.includes(this.type)) {
        return true
      }
      return false
    },
    erlaubteAktionenNamen() {
      let rv = []
      const config = new Config()
      this.erlaubteAktionen.forEach((element) => {
        let a = config.getAktion(element.ShortGuid)
        rv.push(a[4])
      })
      return rv
    },

    ruleStellvertretenerRequired() {
      if (this.mitStellvertreter) {
        if (this.aktion.StellvertreterRef.ShortGuid == null)
          return "Person muss angegeben werden"
      }
      return true
    },
    KennzeichnenExcludes() {
      // Eigentlich alle Originale des Vorgangs UND sich selbst aussschließen
      let rv = []
      rv.push({ ShortGuid: this.vorgang.ShortGuid })
      let aktionen = this.vorgang.Aktionen.filter((a) =>
        a.__type.includes("Kennzeichnen")
      )
      aktionen.forEach((aktion) => {
        if (aktion.AnzeigeOriginal != null) {
          if (aktion.AnzeigeOriginal.hasOwnProperty("ShortGuid")) {
            rv.push({ ShortGuid: aktion.AnzeigeOriginal.ShortGuid })
          }
        }
      })
      return rv
    },
    ReferenzierenExcludes() {
      // Eigentlich alle Verweise des Vorgangs UND sich selbst aussschließen
      let rv = []
      rv.push({ ShortGuid: this.vorgang.ShortGuid })

      let aktionen = this.vorgang.Aktionen.filter((a) =>
        a.__type.includes("Referenzieren")
      )
      aktionen.forEach((aktion) => {
        if (aktion.AnzeigeZiele != null) {
          aktion.AnzeigeZiele.forEach((ziel) => {
            rv.push({ ShortGuid: ziel.ShortGuid })
          })
        }
      })
      return rv
    },
    editorConfig() {
      // wird definitiv genutzt
      let rv = {
        // The configuration of the editor.
        readOnly: !this.isEditable,
        extraPlugins: "sourcedialog,imageAdd", // ,sourcedialog
        // Nicht ausschließlich genutzt, siehe auch Login.vue
        removePlugins: 'wsc,scayt',
        disableNativeSpellChecker: false,
        entities: false,
      }
      return rv
    },
    klonZielversionen() {
      let values = this.facade.Produktversionen
      values = values.filter((el) => el.ProduktRef.ShortGuid == this.produkt)
      return values
    },
    subtitle() {
      return `am ${this.ToDateText(this.aktion.Zeitpunkt)} um ${this.ToTimeText(
        this.aktion.Zeitpunkt
      )}`
    },
    editorUrl: function () {
      return "./ckeditor4/ckeditor.js"
    },

    empfaengerIsStelle(): boolean {
      if (this.type == "Weiterleiten") {
        if (this.aktion.EmpfaengerRef == null) return false
        let a = this.facade.AnzeigeAktuere.find(
          (el) => el.ShortGuid == this.aktion.EmpfaengerRef.ShortGuid
        )
        if (a == null) return false
        return !a.IstPerson
      }
      return false
    },
    justCreated(): boolean {
      return (this.aktion.State & DtoState.New) == DtoState.New
    },
    isEditable(): boolean {
      const e = (this.aktion.State & DtoState.Edit) == DtoState.Edit
      const n = (this.aktion.State & DtoState.New) == DtoState.New
      return e || n
    },
    isDeletable(): boolean {
      if (this.justCreated) return false
      if (this.type == "Melden" || this.type == "Weiterleiten") {
        return false
      }

      // Dann die Aktion prüfen
      let aktionDeletable =
        (this.aktion.State & DtoState.Remove) === DtoState.Remove
      return aktionDeletable
    },
    docurl(): string {
      return (
        $geoGlobals.ServerAddress.replace(
          "LisaSprService.asmx/",
          "LisaSprDownload.ashx?id="
        ) + encodeURIComponent(this.aktion.ShortGuid)
      )
    },
    oriurl(): string {
      return (
        "/i/spr/" + encodeURIComponent(this.aktion.AnzeigeOriginal.ShortGuid)
      )
    },
    duplikaturl(): string {
      return (
        "/i/spr/" + encodeURIComponent(this.aktion.AnzeigeDuplikat.ShortGuid)
      )
    },
    type(): string {
      const lastIndex = this.aktion.__type.lastIndexOf(".")
      return this.aktion.__type.substr(lastIndex + 1).replace("Dto", "")
    },
    aktionName(): string {
      const klasse = this.aktion.__type.replace("Dto", "")
      const conf = new Config()
      let aktion = conf.data.Aktionen.find((el) => {
        return el.Klasse === klasse
      })
      if (aktion != null) {
        if (this.justCreated) {
          return aktion.VerbName
        } else {
          return aktion.AdjektivName
        }
      }
      return this.type()
    },
    melderFilteredInstitutionen: function () {
      let values: [] = this.facade.Institutionen

      let filter = this.melderBundesland

      if (values === undefined) return undefined
      if (filter === undefined || filter == "") return values
      let rvv = values.filter((a: any) => {
        if (filter === null) return true
        return (
          a.BundeslandRef.ShortGuid === filter ||
          a.BundeslandRef.ShortGuid === null
        )
      })
      return rvv.sort((v1: any, v2: any) => {
        return v1.Name.localeCompare(v2.Name)
      })
    },

    vertreterFilteredInstitutionen: function () {
      let values: [] = this.facade.Institutionen

      let ffilter = this.vertreterBundesland

      if (values === undefined || ffilter === undefined) return undefined
      let rvv = values.filter((a: any) => {
        if (ffilter === null) return true
        return (
          a.BundeslandRef.ShortGuid === ffilter ||
          a.BundeslandRef.ShortGuid === null
        )
      })
      // TODO nicht richtig, rvv sollte funktionieren
      return values
    },
    akteurInstitutionRef: function () {
      return this.facade.Institutionen[0].ShortGuid
    },
    melderFilteredAkteure: function () {
      let values = this.facade.AnzeigeAktuere

      let filter = this.melderInstitution
      if (filter == "" || filter == null) {
        values = values.filter((el) => el.IstPerson == true)
      } else {
        values = values.filter(
          (el) =>
            (el.InstitutionRef == null ||
              el.InstitutionRef.ShortGuid == filter) &&
            el.IstPerson == true
        )
      }
      // Falsche Personen und Stellen aussortieren
      values = values.filter((el) => el.AnzeigeName != "Unbekannt")
      values = values.sort((a, b) => {
        return a.AnzeigeName.localeCompare(b.AnzeigeName)
      })
      return values
    },

    empfaengerFilteredInstitutionen: function () {
      let rv = this.empfaengerInstitutionen
      // Unabhängig von einem Filter aufs Bundesland: Institutionen, für die es keine Personen zur Auswahl gibt brauche ich hier gar nicht erst anzeigen.
      let allePersonen = this.empfaengerAkteure
      let alleInstitutionen = allePersonen.map((a) => a.InstitutionRef?.ShortGuid).filter((el) => el != undefined)
      alleInstitutionen = alleInstitutionen.filter((v, i, a) => a.indexOf(v) == i)
      rv = rv.filter(a => alleInstitutionen.indexOf(a.ShortGuid) !== -1)

      // Jetzt noch auf das aktuelle Bundesland filtern, wenn möglich
      let filter = this.empfaengerBundesland
      if (filter !== null && filter !== "" && filter != undefined) {
        rv = rv.filter((v) => v.BundeslandRef.ShortGuid == filter)
      }
      if (rv.length == 1) {
        this.empfaengerInstitution = rv[0].ShortGuid
      }

      return rv
    },

    empfaengerFilteredBundeslaender: function () {
      let rv = this.empfaengerBundeslaender
      let allePersonen = this.empfaengerAkteure
      let alleInstitutionenRef = allePersonen.map((a) => a.InstitutionRef?.ShortGuid).filter((el) => el != undefined)// filter((v, i, a) => a.indexOf(v) == i)
      let alleInstitutionen = this.empfaengerInstitutionen.filter((v) => alleInstitutionenRef.indexOf(v.ShortGuid) !== -1)
      let alleBundeslaenderRef = alleInstitutionen.map((a) => {
        return a.BundeslandRef.ShortGuid
      }).filter((v, i, a) => a.indexOf(v) == i)
      rv = rv.filter((v) => alleBundeslaenderRef.indexOf(v.ShortGuid) !== -1)
      return rv
    },
    empfaengerFilteredAkteure: function () {
      let values = this.empfaengerAkteure
      values = this.aktionFacade.AkteureReg
      let filter = this.empfaengerInstitution
      if (filter != "" && filter !== null && filter != undefined) {
        values = values.filter(
          (el) =>
          (el.InstitutionRef == null ||
            el.InstitutionRef.ShortGuid == filter) // && el.IstPerson == true
        )
      } else {
        filter = this.empfaengerBundesland
        if (filter != "" && filter !== null && filter != undefined) {
          let alleInstitutionRefsDesBundeslands = this.facade.Institutionen.filter((v) => {
            return v.BundeslandRef.ShortGuid === filter
          })
          let allePersonen = this.empfaengerAkteure
          let alleInstitutionenRef = allePersonen.map((a) => a.InstitutionRef?.ShortGuid).filter((el) => el != undefined)// filter((v, i, a) => a.indexOf(v) == i)
          alleInstitutionRefsDesBundeslands = alleInstitutionRefsDesBundeslands.filter((v) =>
            alleInstitutionenRef.indexOf(v.ShortGuid) !== -1
          ).map((el) => el.ShortGuid)
          // Personen auf die Institutionen des Bundeslandes filtern
          values = values.filter(
            (el) => {
              return (el.InstitutionRef == null ||
                alleInstitutionRefsDesBundeslands.indexOf(el.InstitutionRef.ShortGuid) !== -1) // && el.IstPerson == true
            }
          )
        }
      }


      filter = this.aktion.Folgestatus
      if (filter != undefined) {
        values = values.filter((el) => { return el.VorgangStatus.includes(filter) })
      }

      // Falsche Personen und Stellen aussortieren
      values = values.filter((el) => el.AnzeigeName != "Unbekannt")
      values = values.sort((a, b) => {
        if (a.IstPerson && !b.IstPerson)
          return 100
        if (!a.IstPerson && b.IstPerson)
          return -100
        return a.AnzeigeName.localeCompare(b.AnzeigeName)
      })
      return values
    },

    vertreterFilteredAkteure: function () {
      return this.facade.AnzeigeAktuere
    },
    modified: function () {
      return this.isModified()
    },
  },
  watch: {
    mitStellvertreter: {
      handler(val, oldV) {
        // if(val===true)
        {
          this.melderBundesland = null
          this.melderInstitution = null
          this.$refs.Melder.reset()
        }
      },
    },
    "aktion.AnzeigeZiele": {
      handler(val, oldV) {
        this.updateModified()
      },
    },
    "aktion.Wiedervorlage": {
      handler(val, oldV) {
        this.updateModified()
      },
    },
    "aktion.Zielversion": {
      handler(val, oldV) {
        this.updateModified()
      },
    },
    "aktion.AnzeigeOriginal.ShortGuid": {
      handler(val, oldV) {
        this.updateModified()
      },
    },
    "aktion.Folgestatus": {
      handler(val, oldV) {
        // TODO Wahrscheinlich wäre !="Weiterleiten" korrekt
        if (this.type == "Pausieren" || this.type == "Melden") return

        let filter = this.aktion.Folgestatus

        if (filter == undefined) {
          this.empfaengerAkteure = this.aktionFacade.AkteureReg
          this.empfaengerInstitutionen = this.facade.Institutionen.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
          this.empfaengerBundeslaender = this.facade.Bundeslaender.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
          return
        }
        /*
        let conf = new Config()
        filter = conf.data.Folgestatus.find(a => a.TypId == filter)
  
        if (filter == undefined) {
          filter = conf.data.Folgestatus.find(a => {
            return a.ShortId == filter
          })
        }
        filter = filter.TypId
        */
        // Personen aufgrund der Tätigkeiten reduzieren
        let values = this.aktionFacade.AkteureReg
        //values = values.filter((el) => {
        //  return el.VorgangStatus.find((a) => a == filter) != undefined
        //})
        this.empfaengerAkteure = values
        if (this.empfaengerAkteure.length == 1) {
          this.aktion.EmpfaengerRef.ShortGuid = this.empfaengerAkteure[0].ShortGuid
        }

        // Institutionen aufgrund der verbliebenen Akteure reduzieren
        let filters = this.empfaengerAkteure.map(
          (a) => a.InstitutionRef?.ShortGuid
        )
        values = this.facade.Institutionen
        values = values.filter((a) => {
          return filters.find((b) => a.ShortGuid == b) != undefined
        })
        this.empfaengerInstitutionen = values.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
        if (this.empfaengerInstitutionen.length == 1) {
          this.empfaengerInstitution = this.empfaengerInstitutionen[0].ShortGuid
        }

        // Bundesländer aufgrund der verbliebenen Institutionen reduzieren
        values = this.facade.Bundeslaender
        filters = this.empfaengerInstitutionen.map(
          (a) => a.BundeslandRef?.ShortGuid
        )
        values = values.filter((a) => {
          return filters.find((b) => a.ShortGuid == b) != undefined
        })
        this.empfaengerBundeslaender = values.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
        if (this.empfaengerBundeslaender.length == 1) {
          this.empfaengerBundesland = this.empfaengerBundeslaender[0].ShortGuid
        }
        this.updateModified()
      },
    },

    empfaengerBundesland: {
      handler(val, oldV) {
        if (val != oldV) {
          // Ich kann nicht einfach annehmen, dass eine Änderung des Bundeslandes die Auswahl der Institution löscht, da der
          // Nutzer auch eine Person gewählt haben kann, die die Institution bestimmt, die wiederum das Bundesland bestimmt.

          let temp = this.facade.Institutionen.find((a) => a.ShortGuid == this.empfaengerInstitution)
          if (temp != undefined) {
            let currentInstitutionBundesland = temp.BundeslandRef.ShortGuid
            if (currentInstitutionBundesland != this.empfaengerBundesland && this.empfaengerBundesland != undefined) {
              this.empfaengerInstitution = null
            }
          }

          // Eine echte Person kann auch nicht zu mehreren Institutionen gehören, aber Fallmanager und Anwenderbetreuer, deshalb:
          let possiblePersonen = this.empfaengerFilteredAkteure.map((a) => a.ShortGuid)
          if (possiblePersonen.indexOf(this.aktion.EmpfaengerRef.ShortGuid) === -1)
            this.aktion.EmpfaengerRef.ShortGuid = null
        }
      }
    },
    melderBundesland: {
      handler(val, oldV) {
        if (this.aktionFacade == null) {
          return
        }
        // Institutionen aufgrund der Bundesländer reduzieren
        let filter = this.empfaengerBundesland
        let values = this.facade.Institutionen
        values = values.filter(
          (a) => a.BundeslandRef.ShortGuid == filter || a.BundeslandRef == null
        )
        this.empfaengerInstitutionen = values.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
        if (this.empfaengerInstitutionen.length == 1) {
          this.empfaengerInstitution = this.empfaengerInstitutionen[0].ShortGuid
        }

        // Akteure aufgrund der Institutionen reduzieren
        values = this.aktionFacade.AkteureReg
        let filters = this.empfaengerInstitutionen.map((a) => a.ShortGuid)
        values = values.filter((p) => {
          if (p.InstitutionRef == null) return true
          return (
            filters.find((a) => {
              return p.InstitutionRef.ShortGuid == a
            }) != undefined
          )
        })
        this.empfaengerAkteure = values
        if (this.empfaengerAkteure.length == 1) {
          this.aktion.EmpfaengerRef.ShortGuid = this.empfaengerAkteure[0].ShortGuid
        }

        // Alle Tätigkeiten wieder ermöglichen
        let allowed = this.aktionFacade.FolgestatusRefs.map((x) => x.ShortGuid)
        values = new Config().data.Folgestatus.filter((a) => {
          let hit = allowed.find((x) => x == a.ShortGuid)
          return hit != undefined
        })

        /* console.dir("Folgestatuus", this.aktionFacade.FolgestatusRefs.map(a => a.ShortGuid))
        console.dir("facade", this.aktionFacade.AkteureReg.map(a => a.VorgangStatus).flat().filter((v, i, s) => { return s.indexOf(v) == i }))
        let conf = new Config()
        console.dir("config", conf.data.VorgangStatus.map(a => 
            return { g: a.ShortGuid, i: a.ShortId }
        }))
        */

        this.empfaengerFolgestatus = values
      },
    },
    empfaengerInstitution: {
      handler(val, oldV) {
        if (val != oldV) {
          let possiblePersonen = this.empfaengerFilteredAkteure.map((a) => a.ShortGuid)
          if (possiblePersonen.indexOf(this.aktion.EmpfaengerRef.ShortGuid) === -1)
            this.aktion.EmpfaengerRef.ShortGuid = null
        }
        /*
        // Akteure aufgrund der Institutionen reduzieren
        let values = this.aktionFacade.AkteureReg
        let filters = this.empfaengerInstitutionen.map((a) => a.ShortGuid)
        values = values.filter((p) => {
          if (p.InstitutionRef == null) return true
          return (
            filters.find((a) => {
              return p.InstitutionRef.ShortGuid == a
            }) != undefined
          )
        })
        this.empfaengerAkteure = values
        if (this.empfaengerAkteure.length == 1) {
          this.aktion.EmpfaengerRef.ShortGuid = this.empfaengerAkteure[0].ShortGuid
        }
        */
        // Alle Tätigkeiten wieder ermöglichen
        let allowed = this.aktionFacade.FolgestatusRefs.map((x) => x.ShortGuid)
        let values = new Config().data.Folgestatus.filter((a) => {
          let hit = allowed.find((x) => x == a.ShortGuid)
          return hit != undefined
        })
        this.empfaengerFolgestatus = values

        // Alle Bundesländer wieder ermöglichen
        this.empfaengerBundeslaender = this.facade.Bundeslaender.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })

        // Das Bundesland der Institution auswählen
        let selectedInstitution = this.facade.Institutionen.find(v => v.ShortGuid == this.empfaengerInstitution)
        if (selectedInstitution != null) {

          this.empfaengerBundesland = selectedInstitution.BundeslandRef.ShortGuid
        }
      },
    },
    "aktion.EmpfaengerRef.ShortGuid": {
      handler(val, oldV) {
        // Alle Tätigkeiten wieder ermöglichen
        if (this.aktionFacade == null) return
        let allowed = this.aktionFacade.FolgestatusRefs.map((x) => x.ShortGuid)
        let config = new Config()

        let values = config.data.Folgestatus.filter((a) => {
          let hit = allowed.find((x) => x == a.ShortGuid)
          return hit != undefined
        })

        this.empfaengerFolgestatus = values

        // Alle Bundesländer wieder ermöglichen
        this.empfaengerBundeslaender = this.facade.Bundeslaender.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })

        // Alle Institutionen wieder ermöglichen
        this.empfaengerInstitutionen = this.facade.Institutionen.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })

        /*/ Institution der Person setzen
        let selectedEmpfaenger = this.empfaengerFilteredAkteure.find(v => v.ShortGuid == this.aktion.EmpfaengerRef.ShortGuid)
        if (selectedEmpfaenger?.InstitutionRef?.ShortGuid != null) {
          this.empfaengerInstitution = selectedEmpfaenger.InstitutionRef.ShortGuid
        } //*/
        this.updateModified()
      },
    },
    empfaengerFolgestatus: {
      handler(val, oldV) {
        if (this.type == "Weiterleiten" && this.justCreated) {
          if (val.length == 0) {
            let allowed = this.aktionFacade.FolgestatusRefs.map(
              (x) => x.ShortGuid
            )
            let config = new Config()
            let cc = config.data.Folgestatus.filter(a => allowed.includes(a.ShortGuid))
            let leute = this.aktionFacade.AkteureReg.map(a => a.VorgangStatus).flat().filter((v, i, s) => { return s.indexOf(v) == i })
            cc = cc.filter(a => leute.includes(a.TypId))
            cc = cc.map(a => a.ShortGuid)
            allowed = allowed.filter(a => cc.includes(a))
            let values = new Config().data.Folgestatus.filter((a) => {
              let hit = allowed.find((x) => x == a.ShortGuid)
              return hit != undefined
            })

            this.empfaengerFolgestatus = values
          }
        }
      },
      immediate: true,
    },
    empfaengerBundeslaender: {
      handler(val, oldV) {
        if (this.type == "Weiterleiten") {
          if (val.length == 0) {
            this.empfaengerBundeslaender = this.facade.Bundeslaender.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
          }
        }
      },
      immediate: true,
    },
    empfaengerInstitutionen: {
      handler(val, oldV) {
        if (this.type == "Weiterleiten") {
          if (val.length == 0) {
            this.empfaengerInstitutionen = this.facade.Institutionen.sort((v1, v2) => { return v1.Name.localeCompare(v2.Name) })
          }
        }
      },
      immediate: true,
    },
    empfaengerAkteure: {
      handler(val, oldV) {
        if (this.type == "Weiterleiten" && this.justCreated) {
          if (val == null || val.length == 0) {
            this.empfaengerAkteure = this.aktionFacade.AkteureReg
          }
        }
      },
      immediate: true,
    },

    produkt: {
      handler(val, oldV) {
        // TODO Warum gibt es das hier überhaupt?

        if (this.type == "Weiterleiten" && this.justCreated) {
          // Empfänger-Optionen aktualisieren
          let pi = this.produkt
          let p = this.facade.Produkte.find((el) => (el) =>
            el.ShortGuid === pi
          )

          const auth = {
            headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
          }
          let post = this.$http.post(
            $geoGlobals.ServerAddress + "AktionWeiterleitenNeu",
            { dtoProdukt: p },
            auth
          )
          post.then(
            (result) => {
              result = result.data.d
              this.aktion.ProduktAkteure = result.ProduktAkteure
            },
            (error) => {
              console.error("refresh-error", error)
            }
          )
        }
      },
    },
    file: {
      handler(val, oldV) {
        // Im Fall von "multiple" wäre val ein Array
        if (val == null) {
          return
        }
        let filesize = val.size
        let maxMegabytes = new Config().MaxFileSize
        if (filesize > maxMegabytes * 1024 * 1024) {
          this.$event.$emit(
            "global-snackbar",
            `Dateigröße überschreitet das erlaubte Limit von ${maxMegabytes} MB`
          )
          this.file = null
          this.aktion.Dokumentname = null
          this.aktion.MimeType = null
          this.$refs.fileinput.reset()
          return
        }
        this.aktion.Dokumentname = val.name
        this.aktion.MimeType = val.type
        this.updateModified()
      },
    },
    "aktion.StellvertreterRef.ShortGuid": {
      immediate: true,
      handler(val, oldV) {
        if (this.type === "Melden") {
          if (val == null) return
          // val = val.ShortGuid;
          // Zur neuen Person die Institution ermitteln
          let p = this.facade.AnzeigeAktuere.find((el) => el.ShortGuid == val)

          if (p.InstitutionRef == null) return

          let ig = p.InstitutionRef.ShortGuid

          this.melderInstitution = ig
          if (this.justCreated) {
            this.updateModified()
          }
        }
      },
    },

    melderInstitution: {
      immediate: true,
      handler(val, oldV) {
        if (this.type == "Melden" && val !== "" && val != undefined) {
          // Zur neuen Institution das Bundesland finden
          let i = this.facade.Institutionen.find((el) => el.ShortGuid == val)
          let bg = i.BundeslandRef.ShortGuid
          this.melderBundesland = bg
        }
      },
    },
    vertreterInstitution: {
      immediate: true,
      handler(val, oldV) {
        if (this.type == "Melden") {
          // Zur neuen Institution das Bundesland finden
          let i = this.facade.Institutionen.find((el) => el.ShortGuid == val)
          if (i == null) return
          let bg = i.BundeslandRef.ShortGuid
          this.vertreterBundesland = bg
        }
      },
    },
  },

  methods: {
    resetModified() {
      this.aktionAsLoaded = JSON.stringify(this.aktion)
      if (this.$refs.theEditor)
        if (this.$refs.theEditor.instance)
          this.$refs.theEditor.instance.resetDirty()
      this.updateModified()
    },
    validate() {
      if (this.justCreated && this.type == "Melden") {
        if (this.aktion.Beschreibung.length > 0) {
          this.errorMeldung = ""
          return true
        } else {
          this.errorMeldung = "Bitte geben Sie einen Text ein"
          return false
        }
      }
      let rv = this.allowedAction
      if (rv == false) return
      this.errorMeldung = ""
      if (
        [
          "Melden",
          "Weiterleiten",
          "Abschliessen",
          "Einstellen",
          "Kommentieren",
        ].includes(this.type)
      ) {
        if (this.aktion.Beschreibung.length > 0) {
          this.errorMeldung = ""
        } else {
          this.errorMeldung = "Bitte geben Sie einen Text ein"
          rv = false
        }
      }

      if (this.justCreated) {
        if (this.type == "Melden") {
          if (this.ruleStellvertretenerRequired !== true) {
            rv = false
            this.$refs.Melder.validate()
          }
        } else if (this.type == "Kennzeichnen") {
          if (this.aktion.AnzeigeOriginal === null) {
            this.errorMeldung =
              "Bitte wählen Sie das Original für dieses Duplikat"
            rv = false
          }
        } else if (this.type == "Referenzieren") {
          if (
            this.aktion.AnzeigeZiele === null ||
            this.aktion.AnzeigeZiele.length === 0
          ) {
            this.errorMeldung = "Bitte wählen Sie mindestens ein Referenzziel"
            rv = false
          }
        }
      }
      return rv
    },

    updateModified() {
      let newValue = false
      if (
        this.$refs.theEditor == undefined ||
        this.$refs.theEditor.instance == undefined
      ) {
        if (newValue != this.aktionModified) {
          this.aktionModified = newValue
          this.$event.$emit("aktion-modified-changed", this.aktionModified)
        }
        return
      }

      if (this.justCreated) {
        let ori = JSON.parse(this.aktionAsLoaded)
        // Wenn es neu ist, werte ich auch die übrigen Felder aus, nicht nur die Beschreibung
        if (this.type == "Melden") {
          // Auf Stellvertreter prüfen
          if (this.mitStellvertreter) {
            newValue =
              newValue ||
              ori.StellvertreterRef.ShortGuid !=
              this.aktion.StellvertreterRef.ShortGuid
          }
        } else if (this.type == "Weiterleiten") {
          newValue = newValue || ori.Folgestatus != this.aktion.Folgestatus
          newValue =
            newValue ||
            ori.EmpfaengerRef.ShortGuid != this.aktion.EmpfaengerRef.ShortGuid
        } else if (this.type == "Kennzeichnen") {
          // aktion.AnzeigeOriginal.ShortGuid
          newValue =
            newValue ||
            ori.AnzeigeOriginal.ShortGuid !=
            this.aktion.AnzeigeOriginal.ShortGuid
        } else if (this.type == "Duplizieren") {
          newValue = newValue || ori.Zielversion != this.aktion.Zielversion
        } else if (this.type == "Pausieren") {
          newValue = newValue || ori.Wiedervorlage != this.aktion.Wiedervorlage
        } else if (this.type == "Referenzieren") {
          // TODO Möglicherweise nicht wasserdicht, aber genug, um ein neues-unbearbeitetes von einem neuen-bearbeiteten zu unterscheiden
          newValue = newValue || ori.AnzeigeZiele != this.aktion.AnzeigeZiele
        } else if (this.type == "Dokumentieren") {
          // TODO Möglicherweise nicht wasserdicht, aber genug, um ein neues-unbearbeitetes von einem neuen-bearbeiteten zu unterscheiden
          let oldV = ori.file || []
          let newV = this.file || []
          newValue = newValue || JSON.stringify(oldV) != JSON.stringify(newV)
        }
      } else {
        if ((this.aktion.State & DtoState.Removed) == DtoState.Removed) {
          newValue = true
        }
      }

      if (this.type == "Kommentieren") {
      }

      newValue = newValue || this.$refs.theEditor.instance.checkDirty()
      newValue = newValue // || this.justCreated
      if (newValue != this.aktionModified) {
        this.aktionModified = newValue
        this.$event.$emit("aktion-modified-changed", this.aktionModified)
      }
    },
    editorChanged: function (aaa, bbb) {
      if (bbb == undefined) {
        this.$refs.theEditor.instance.resetDirty()
      }

      this.updateModified()
      // this.validate()
    },
    editorReady: function () {
      this.$refs.theEditor.instance.resetDirty()
    },
    isModified: function () {
      return this.aktionModified
    },
    clickAddImage: function () {
      this.imageDialog = false
      var reader = new FileReader()
      reader.onload = (aImg) => {
        this.imageEditor.insertHtml("<img src='" + aImg.target.result + "'>")
      }
      reader.readAsDataURL(this.selectedFile) //
    },

    StorePerson: function () {
      const p = this.tempMelderPerson.Person
      let kleinePerson = {
        AnzeigeName: p.Nachname + ", " + p.Vorname,
        InstitutionRef: p.InstitutionRef,
        IstPerson: true,
        ShortGuid: p.ShortGuid,
      }
      this.facade.AnzeigeAktuere.push(kleinePerson)
      this.aktion.StellvertreterRef.ShortGuid = kleinePerson.ShortGuid
      this.facade.Institutionen = this.tempMelderFacade.Institutionen

      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      // Check if the new Institution is sent to the server
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlSpeichern",
        {
          dtoPerson: p,
          dtoInstitutionen: this.facade.Institutionen,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.$event.$emit(
              "global-snackbar",
              result.Message || "Speichern erfolgreich"
            )
          } else {
            this.$event.$emit(
              "global-snackbar",
              result.Message || "Speichern fehlgeschlagen"
            )
          }
        },
        (error) => {
          console.error("PersonControlSpeichern", error)
          this.$event.$emit("global-snackbar", error)
        }
      )

      this.showDialogNeuerMelder = false
    },
    clickNeuerMelder: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlLaden",
        {},
        auth
      )
      post.then(
        (result) => {
          this.tempMelderFacade = result.data.d
          this.tempMelderFacade.Institutionen.forEach((element) => {
            if (element.BundeslandRef == null) element.BundeslandRef = {}
          })
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("PersonControlLaden", error)
        }
      )

      post = this.$http.post($geoGlobals.ServerAddress + "PersonNeu", {}, auth)
      post.then(
        (result) => {
          result = result.data.d

          this.tempMelderPerson = result
          if (this.tempMelderPerson.Person.InstitutionRef == null) {
            this.tempMelderPerson.Person.InstitutionRef = {}
          }
          this.showDialogNeuerMelder = true
        },
        (error) => {
          console.error("PersonNeu", error)
        }
      )
    },
    clickZielRemoved: function (param1) {
      this.aktion.AnzeigeZiele = this.aktion.AnzeigeZiele.filter(
        (el) => el.ShortGuid != param1
      )
    },
    clickOriginalSelected: function () {
      let selected = this.$refs.sprSelektor._data.selected
      if (selected.length > 0) {
        selected = selected[0]
        this.aktion.AnzeigeOriginal = {
          Nummer: selected.Nummer,
          State: selected.State,
          ShortGuid: selected.ShortGuid,
        }
        this.validate()
      }
      this.sprSingleSelect = false
    },
    clickReferenzenSelected: function () {
      let selected = this.$refs.sprMultiSelektor._data.selected
      if (selected.length > 0) {
        this.aktion.AnzeigeZiele = []
        selected.forEach((el) => {
          let neu = {
            Nummer: el.Nummer,
            State: el.State,
            ShortGuid: el.ShortGuid,
          }
          this.aktion.AnzeigeZiele.push(neu)
        })
        this.validate()
      }
      this.sprMultiSelect = false
    },
    removeAction: function (param) {
      this.vorgang.Aktionen = this.vorgang.Aktionen.filter(
        (el) => el !== this.aktion
      )
      this.$event.$emit("aktion-modified-changed", false)
    },
    deleteAction: function (param) {
      this.aktion.State |= DtoState.Removed
      this.updateModified()
    },
    ToFolgestatus(id: string): string {
      const config = new Config()
      let v = config.data.VorgangStatus.find((el) => el.ShortId == id)
      return v.Name
    },
    ToDateText(date): string {
      if (typeof date == "string") {
        let matches = date.match(/(\d+)/)
        date = new Date(parseInt(matches[0]))
      }

      let rv =
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      return rv
    },
    ToTimeText(date): string {
      return date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2)
    },
    ToLangname(id: string): string {
      let akteur = this.facade.AnzeigeAktuere.find((el) => el.ShortGuid == id)
      if (akteur == undefined) return id
      return akteur.AnzeigeName
    },
    ToTooltip(id: string): string {
      let akteur = this.facade.AnzeigeAktuere.find((el) => el.ShortGuid == id)
      if (akteur.InstitutionRef == null) return ""
      let institution = this.facade.Institutionen.find(
        (el) => el.ShortGuid == akteur.InstitutionRef.ShortGuid
      )
      if (institution == null) {
        return akteur.AnzeigeName
      }
      let bundesland = this.facade.Bundeslaender.find(
        (el) => el.ShortGuid == institution.BundeslandRef.ShortGuid
      )
      if (bundesland == null) {
        return `${institution.Name}`
      }
      if (institution.Name.includes(bundesland.Name)) {
        return `${institution.Name}`
      }
      return `${institution.Name}, ${bundesland.Name}`
    },
  },

  data() {
    return {
      produktgruppe: null,
      produkt: null,
      datemenu: false,
      errorMeldung: "",
      aktionAsLoaded: "",
      aktionModified: false,
      selectedFile: null,
      imageDialog: false,
      imageEditor: null,
      tempMelderFacade: null,
      tempMelderPerson: null,
      sprSingleSelect: false,
      sprMultiSelect: false,
      showDialogNeuerMelder: false,
      file: null,
      melderBundesland: "",
      melderInstitution: "",
      vertreterBundesland: "",
      vertreterInstitution: "",
      empfaengerBundesland: "",
      empfaengerInstitution: "",

      empfaengerFolgestatus: [],
      empfaengerBundeslaender: [],
      empfaengerInstitutionen: [],
      empfaengerAkteure: [],
      mitStellvertreter: false,
      dummy: null,
    }
  },
}
