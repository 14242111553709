





































































































































































































































import Vue from "vue"
import Config from "../Config"
import { DtoState, ZustandStatus } from '../FacadeVorgangListe'
import { GeoGlobals } from '../GeoGlobals'
import PersonenControl from './PersonenControl.vue'

const config = new Config()
declare const $geoGlobals: GeoGlobals

export default {
  components: {
    PersonenControl
  },
  mounted() {
    this.storeCurrentState()
  },
  props: ["facade", "vorgang"],
  methods: {

    isEditable(property) {
      const state = property.State
      const rv = (state & DtoState.Edit) == DtoState.Edit
      return rv
    },
    clickInteressierteSelected() {
      let selected = this.$refs.PersonenControl._data.selected
      selected = selected.map(el => el.ShortGuid)
      selected.forEach(element => {
        let neu = { ShortGuid: element }
        this.vorgang.InteressierteRefs.List.push(neu)
      })
      this.personenMultiSelect = false

    },
    storeCurrentState() {
      this.vorgangAsLoaded = JSON.stringify(this.vorgang)
      if (this.vorgangModified == true) {
        this.vorgangModified = false
        this.$event.$emit("vorgang-modified-changed", false)
      }
      this.vorgangModified = false
    },
    clickRemoveMyselfFromInteressierte() {
      this.vorgang.InteressierteRefs.List = this.vorgang.InteressierteRefs.List.filter(
        a => a.ShortGuid != this.userShortGuid
      )
    },
    clickRemoveThisFromInteressierte(tag) {
      this.vorgang.InteressierteRefs.List = this.vorgang.InteressierteRefs.List.filter(
        a => a.ShortGuid != tag.ShortGuid
      )
    },
    clickAddMoreInteressierte() {
      this.personenMultiSelect = true
      this.$nextTick(() => {
        this.$refs.PersonenControl._data.selected = []
      })

    },
    clickAddMyselfToInteressierte() {
      let neu = {
        "__type": "GeoBremen.LisaSpr.DtoVorgang",
        ShortGuid: $geoGlobals.Myself.ShortGuid
      }
      this.vorgang.InteressierteRefs.List.push(neu)

    },
    ToSorty(versionName: string): string {
      let rv = ""
      const parts = versionName.split(".")
      parts.forEach((part, index) => {
        if (index > 0)
          rv += "."
        const parsed = parseInt(part)
        if (isNaN(parsed)) {
          rv += "0000" + part
        } else {
          var s = String(parsed)
          while (s.length < 3)
            s = "0" + s
          rv += s
        }
      })
      return rv
    },
    isModified: function () {
      let vorgangAsNow = JSON.stringify(this.vorgang)
      let temp = JSON.parse(vorgangAsNow)
      temp.Aktionen = []
      vorgangAsNow = JSON.stringify(temp)
      temp = JSON.parse(this.vorgangAsLoaded)
      temp.Aktionen = []
      let vorgangAsFirst = JSON.stringify(temp)
      let rv = vorgangAsNow != vorgangAsFirst
      if (rv !== this.vorgangModified) {
        this.vorgangModified = rv
        this.$event.$emit("vorgang-modified-changed", this.vorgangModified)
      }
      return rv
    },
    ToVorgangStatus(id: number): string {
      let config = new Config()
      const parts = config.getVorgangStatus(id)
      return parts[1]
    },
    typLabel(id: number): string {
      return config.getVorgangTyp(id)[1]
    },

    prioLabel(prioId: number) {
      return config.getVorgangPriorität(prioId)[1]
    },

    /*productversionLabel(id: string): string {
      return config.getProductversion(id)[0];
    },
    productLabel(id: string): string {
      return config.getProduct(id)[0];
    },*/
    selectedSchlagwort: function (item) {
      if (item.Kuerzel && item.Kuerzel.length > 0)
        return item.Kuerzel
      return item.Name
    },
    selectableSchlagwort: function (item) {
      if (item.Kuerzel && item.Kuerzel.length > 0) {
        let append = " (" + item.Kuerzel + ")"
        if (!item.Name.endsWith(append))
          return item.Name + append
      }
      return item.Name
    }
  },
  computed: {
    goVertigis() {

      let potLink = this.vorgang.FremdId.Value
      if (potLink == null) {
        return ""
      }
      potLink = potLink.toLowerCase()
      if (!potLink.startsWith("vertigis #") && !potLink.startsWith("vertigis#")) {
        return ""
      }
      while ((isNaN(potLink[0]) || potLink[0] == ' ') && potLink.length > 0) {
        potLink = potLink.substring(1)
      }
      if (potLink.trim().length == 0) {
        return ""
      }
      return "https://support.vertigis.com/hc/de/requests/" + potLink
    },
    isMeldezustand: function () {
      let v = this.vorgang?.AnzeigeMeldeZustand?.Name
      return v != null
    },
    ToProdukt: function () {
      if (this.vorgang.produktName == null)
        return ""
      let values: [] = this.facade.Produkte
      let pr: any = values.find((a: any) => {
        return a.ShortGuid == this.vorgang.produktName
      })
      if (!pr)
        return "error"
      this.produktName = ", " + pr.Name
      return this.produktName
    },
    isAnwenderbetreuung: function () {
      return this.vorgang.Typ.Value == 4
    },
    sortedGruppen: function () {
      this.facade.Gruppen.sort((v1, v2) => {
        return v1.Name.localeCompare(v2.Name)
      })
      return this.facade.Gruppen
    },
    InteressierteAlreadyListed: function () {
      const self = $geoGlobals.Myself.ShortGuid
      const values = this.interessierte
      const rv = values.find(a => {
        return a.ShortGuid == self
      })
      return rv != undefined
    },
    userName: function () {
      return $geoGlobals.Myself.Name
    },
    userShortGuid: function () {
      return $geoGlobals.Myself.ShortGuid
    },
    modified: function () {
      return this.isModified()
    },
    schlagwortOptionen: function () {
      if (this.produktgruppe == null)
        return []
      let values = this.facade.SchlagwortArten
      values = values.filter(v => v.GruppeRef.ShortGuid == this.produktgruppe)

      // Kürzel anhängen, wenn vorhanden
      values.forEach(element => {
        if (element.Kuerzel && element.Kuerzel.length > 0) {
          element.Name = element.Name + " (" + element.Kuerzel + ")"
        }
      })
      return values
    },

    isProduktgruppeEditable: function () {
      return this.isProduktEditable
    },
    isProduktEditable: function () {
      const ms = this.vorgang.MeldeversionRef.State
      const zs = this.vorgang.ZielversionRef.State

      const me = (ms & DtoState.Edit) == DtoState.Edit
      const ze = (zs & DtoState.Edit) == DtoState.Edit

      // Keine Version editierbar: einfach
      if (!me && !ze) {
        return false
      }

      // Beide Versionen editierbar: einfach
      if (me && ze)
        return true

      // Eine Version editierbar, die andere nicht: aufpassen
      if (me && (this.vorgang.ZielversionRef.ShortGuid == null)) {
        return true
      }
      if (ze && (this.vorgang.MeldeversionRef.ShortGuid == null)) {
        return true
      }
      return false
    },
    interessierte: function () {
      if (this.vorgang.InteressierteRefs == null) return []
      if (this.vorgang.InteressierteRefs.List == null) return []
      if (this.vorgang.InteressierteRefs.List.length == 0) return []
      let liste = this.vorgang.InteressierteRefs.List.map(el => el.ShortGuid)
      let rv = this.facade.AnzeigeAktuere.filter(el => {
        return liste.includes(el.ShortGuid)
      })
      return rv
    },
    originale: function () {
      let kennzeichnungen = this.vorgang.Aktionen.filter(
        el => {
          return el.__type.endsWith("Kennzeichnen") && el.AnzeigeOriginal != null && ((el.State & DtoState.New) != DtoState.New)
        }
      )
      let registeredNumbers = []
      let noDuplicates = []
      kennzeichnungen.forEach((a) => {
        if (registeredNumbers.indexOf(a.AnzeigeOriginal.Nummer) == -1) {
          registeredNumbers.push(a.AnzeigeOriginal.Nummer)
          noDuplicates.push(a)
        }
      })
      noDuplicates.sort((a, b) => {
        return b.AnzeigeOriginal.Nummer - a.AnzeigeOriginal.Nummer
      })
      return noDuplicates
    },
    duplikate2: function () {
      let rv = []
      if (!this.vorgang.AnzeigeDuplizierungen) return rv
      this.vorgang.AnzeigeDuplizierungen.forEach(el => {
        let entry = { Nummer: el.Nummer, ShortGuid: el.ShortGuid }
        rv.push(entry)
      })
      return rv
    },
    duplikate1: function () {
      let rv = []

      let klone = this.vorgang.Aktionen.filter(el =>
        el.__type.endsWith("Duplizieren") && !el.IstKopie
      )

      klone.forEach(el => {
        if (el.AnzeigeDuplikat != null) {
          let entry = {
            Nummer: el.AnzeigeDuplikat.Nummer,
            ShortGuid: el.AnzeigeDuplikat.ShortGuid
          }
          rv.push(entry)
        }
      })
      return rv
    },
    typen: function () {
      // let typen = config.data.VorgangTypen;
      let typen = $geoGlobals.configData.VorgangTypen

      typen.sort((a, b) => {
        const order = ["k", "Anw", "Anf", "W", "G"]
        let indexA = order.findIndex((el) => {
          return a.Name.toLowerCase().startsWith(el.toLowerCase())
        })
        let indexB = order.findIndex((el) => {
          return b.Name.toLowerCase().startsWith(el.toLowerCase())
        })
        return indexA - indexB
      })
      return typen
    },
    prios: function () {
      let prios = $geoGlobals.configData.VorgangPrios
      prios.sort((a, b) => {
        const order = ["u", "g", "n", "d", "k"]
        let indexA = order.findIndex((el) => {
          return a.Name.toLowerCase().startsWith(el.toLowerCase())
        })
        let indexB = order.findIndex((el) => {
          return b.Name.toLowerCase().startsWith(el.toLowerCase())
        })
        return indexA - indexB
      })
      return prios
    },
    filteredProdukte: function () {
      let values: [] = this.facade.Produkte
      let ffilter = this.produktgruppe
      if (ffilter == null) return values
      let rvv = values.filter((el: any) => {
        return el.GruppeRef.ShortGuid == ffilter
      })

      let fitting = rvv.filter((el: any) => {
        return el.ShortGuid == this.produkt
      })
      if (fitting.length == 0) {
        this.produkt = null
        this.$event.$emit("produktChanged", null)
      }
      return rvv
    },
    filteredMeldeversionen:
      function () {
        let values: [] = this.facade.Produktversionen
        let ffilter = this.produkt
        if (values === undefined || ffilter === undefined) return undefined
        let rvv = values.filter((a: any) => {
          return a.ProduktRef.ShortGuid === ffilter
        })
        // Keine geplanten oder abgekündigten Versionen
        // Achtung! Die eingetragene Version muss natürlich trotzdem angeboten werden.
        rvv = rvv.filter((a: any) => {
          if (a.ShortGuid === this.vorgang.MeldeversionRef.ShortGuid) {
            return true
          }
          return (a.AktuellerZustandStatus != ZustandStatus.Geplant && a.AktuellerZustandStatus != ZustandStatus.Abgekuendigt)
        })
        rvv.sort((a: any, b: any) => {
          return this.ToSorty(b.Name).localeCompare(this.ToSorty(a.Name))
        })
        // Wenn die aktuelle Meldeversion mit diesen Optionen nicht angezeigt werden kann,
        // muss die zurückgesetzt werden.

        if (this.vorgang.MeldeversionRef.ShortGuid) {
          let currentMeldeversion = this.vorgang.MeldeversionRef.ShortGuid
          let options = rvv.map((a: any) => a.ShortGuid)
          if (options.indexOf(currentMeldeversion) == -1) {
            // this.vorgang.MeldeversionRef = {}
            this.vorgang.MeldeversionRef.ShortGuid = ""
            // SPR4301 Die aktuelle Meldeversion passt nicht, wenn das Profukt wechsel, dann werden die Rechte neu abgefragt,
            // wozu eine Meldeversion benötigt wird. Deshalb setze ich jetzt hier die erste Meldeversion
            if (this.$refs.meldeversionControl != null) {
              this.$refs.meldeversionControl.resetValidation()
            }
          }
        } else {
          if (this.$refs.meldeversionControl != null) {
            this.$refs.meldeversionControl.resetValidation()
          }
        }
        if (rvv.length > 0) {
          let ppp = this.vorgang.MeldeversionRef.ShortGuid
          // Solves 3862, causes 4301!
          // this.vorgang.MeldeversionRef.ShortGuid = rvv[0]['ShortGuid']

          // this.vorgang.MeldeversionRef = rvv[0]
        }
        return rvv
      },
    filteredZielversionen: function () {
      let values: [] = this.facade.Produktversionen
      let ffilter = this.produkt
      if (values === undefined || ffilter === undefined) return undefined
      let rvv = values.filter((a: any) => {
        return a.ProduktRef.ShortGuid === ffilter
      })
      // Keine freigegebenen oder abgekündigten Versionen
      // Achtung! Die eingetragene Version muss natürlich trotzdem angeboten werden.
      rvv = rvv.filter((a: any) => {
        if (a.ShortGuid === this.vorgang.ZielversionRef.ShortGuid) {
          return true
        }
        return (a.AktuellerZustandStatus != ZustandStatus.Freigegeben && a.AktuellerZustandStatus != ZustandStatus.Abgekuendigt)
      })

      rvv.sort((a: any, b: any) => {
        return this.ToSorty(b.Name).localeCompare(this.ToSorty(a.Name))
      })

      // Wenn die aktuelle Zielversion mit diesen Optionen nicht angezeigt werden kann,
      // muss die zurückgesetzt werden.
      if (this.vorgang.ZielversionRef.ShortGuid) {
        let currentZielversion = this.vorgang.ZielversionRef.ShortGuid
        let options = rvv.map((a: any) => a.ShortGuid)
        if (options.indexOf(currentZielversion) == -1) {
          if (this.vorgang.ZielversionRef) {
            this.vorgang.ZielversionRef.ShortGuid = null
          } else {
            this.vorgang.ZielversionRef = {}
          }
          if (this.$refs.zielversionControl != null) {
            this.$refs.zielversionControl.resetValidation()
          }
        }
      }
      return rvv
    }
  },
  watch: {
    $route() {
      // Das folgende ist nötig, weil beim Wechsel von Detailansicht zu Detailansicht
      // Produkt und Produktgruppe aus der Ansicht verschwinden, wenn sie sich nicht ändern.
      // (In der Anzeige geht er verloren, wird aber nicht neu gerendert, weil das Model
      // sich nicht geändert hat.)
      this.produkt = null
      this.produktgruppe = null
    },
    vorgang: {
      deep: true,
      handler(val, oldV) {
        this.isModified()
      }
    },
    // This is only relevant once after loading a Vorgang.
    "vorgang.MeldeversionRef": {
      immediate: true,
      handler(val, oldV) {
        // Der Wechsel der Meldeversion ist nicht wirklich wichtig, aber da die Version produktabhängig ist, repräsentiert
        // die Meldeversion das Produkt. Und ein Produktwechsel verlangt, dass die Rechte neu ausgewertet werden.
        console.debug("watch(vorang.MeldeversionRef)")
        if (val == null) {
          return
        }

        val = val.ShortGuid
        let version = this.facade.Produktversionen.find(
          el => el.ShortGuid === val
        )
        if (version === undefined) {
          return
        }
        if (this.produkt != version.ProduktRef.ShortGuid) {
          console.debug("\tcalled with impact (produkt has changed)")
          this.produkt = version.ProduktRef.ShortGuid
          this.counter += 1
          this.$forceUpdate()
        }
        else {
          console.debug("\tunnecessary call")
        }
      }
    },
    produkt: {
      immediate: true,
      handler(val, oldV) {
        if (val == null) return
        // ProduktId zu Produkt
        let produkt = this.facade.Produkte.find(el => el.ShortGuid === val)

        if (produkt != undefined && this.filteredMeldeversionen[0] != undefined) {
          // Erster Grund: Das Feld ist leer.
          let needNewMeldeversion = this.vorgang.MeldeversionRef.ShortGuid == "" || this.vorgang.MeldeversionRef.ShortGuid == null
          // Zweiter Grund: Der Wert ist alt.
          if (!needNewMeldeversion) {
            let options = this.filteredMeldeversionen.map(a => a.ShortGuid)
            if (!options.includes(this.vorgang.MeldeversionRef.ShortGuid)) {
              needNewMeldeversion = true
            }
          }
          if (needNewMeldeversion) {
            this.vorgang.MeldeversionRef.ShortGuid = this.filteredMeldeversionen[0].ShortGuid
          }
        }
        this.produktgruppe = produkt.GruppeRef.ShortGuid
        this.$nextTick(() => { this.$event.$emit("produktChanged", produkt.ShortGuid) })

      }
    }
  },
  data() {
    return {
      counter: 0,
      personenMultiSelect: false,
      vorgangModified: false,
      vorgangAsLoaded: "",
      selectedPrio: [],
      ProduktId: undefined,
      produktgruppe: null,
      produkt: null,
      produktName: null
    }
  }
}
