// AAAA import 'core-js'
// import 'typeface-roboto/index.css';
require('typeface-roboto')
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import CKEditor from 'ckeditor4-vue'
import Vuetify from 'vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

import router from './Router'

import App from './views/App.vue'

import { GeoGlobals } from './GeoGlobals'

const geoGlobals = new GeoGlobals();

(window as any).$geoGlobals = geoGlobals;
(window as any).ExcelExport = true

Vue.use(Vuetify)
import de from 'vuetify/src/locale/de'

Vue.use(CKEditor)

axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

Vue.prototype.$event = new Vue()

new Vue({

    vuetify: new Vuetify(
        {
            lang: {
                locales: { de },
                current: 'de',
            },
        }
    ), router, render: createElement => createElement(App)
}).$mount('#app')
