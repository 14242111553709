



































































































































import Vue from "vue"
export default Vue.extend({
  name: "personen-control",
  props: ["facade", "vorgang", "modus"],
  computed: {
    filteredPersonen: function () {
      if (this.filterBundesländer && this.filterBundesländer.length == 0)
        this.filterBundesländer = null
      if (this.filterInstitutionen && this.filterInstitutionen.length == 0)
        this.filterInstitutionen = null
      let values = this.facade.AnzeigeAktuere.filter((el) => {
        if (el.AnzeigeName == "Unbekannt") return false
        return el.IstPerson == true
      })

      if (this.vorgang != null) {
        const alreadyInteressiert = this.vorgang.InteressierteRefs.List.map(
          (el) => el.ShortGuid
        )
        values = values.filter((el) => {
          return !alreadyInteressiert.includes(el.ShortGuid)
        })
      }
      if (this.filterBundesländer != null && this.filterInstitutionen == null) {
        values = values.filter((per) => {
          if (
            per.InstitutionRef == null ||
            per.InstitutionRef.ShortGuid == null
          )
            return true
          let i = this.facade.Institutionen.find((inst) => {
            return inst.ShortGuid == per.InstitutionRef.ShortGuid
          })
          if (
            i == null ||
            i == undefined ||
            i.BundeslandRef == null ||
            i.BundeslandRef.ShortGuid == null
          ) {
            return true
          }
          let b = this.facade.Bundeslaender.find((bun) => {
            return bun.ShortGuid == i.BundeslandRef.ShortGuid
          })
          if (b == null) return true
          if (this.filterBundesländer.includes(b.ShortGuid)) return true
          return false
        })
      } else if (this.filterInstitutionen != null) {
        values = values.filter((per) => {
          if (
            per.InstitutionRef == null ||
            per.InstitutionRef.ShortGuid == null
          ) {
            return true
          }
          if (this.filterInstitutionen.includes(per.InstitutionRef.ShortGuid))
            return true
          return false
        })
      }
      return values
    },
    filteredInstitutionen: function () {
      let values = this.facade.Institutionen
      values = values.filter((el) => {
        if (el.BundeslandRef == null || el.BundeslandRef.ShortGuid == null)
          return true
        if (this.filterBundesländer == null) return true
        if (this.filterBundesländer.includes(el.BundeslandRef.ShortGuid))
          return true
        return false
      })

      if (this.filterInstitutionen != null) {
        // Wenn Filter nicht angezeigt werden kann, Filter entfernen
        let potentielleInstitutionenGuids = values.map((el) => el.ShortGuid)
        this.filterInstitutionen = this.filterInstitutionen.filter((el) => {
          let rv = potentielleInstitutionenGuids.includes(el)
          return rv
        })
      }
      return values // .map(el => el.ShortGuid);
    },
  },
  methods: {
    clickNew: function () {
      this.$event.$emit("new-person")
    },
    clickEdit: function (param) {
      this.$event.$emit("edit-person", param)
    },
    clickDelete: function (param) {
      this.$event.$emit("delete-person", param)
    },
    clickEditRoles: function (param) {
      this.$event.$emit("edit-roles", param)
    },
    MyFilter: function (value: any, search: string | null, item: any) {
      if (value == null) return false

      if (value.hasOwnProperty("ShortGuid")) return false
      value = value.toLowerCase()
      search = search.toLowerCase()
      if (value.includes(search)) return true
      return false
    },
    resetFilters: function () {
      this.filterBundesländer = null
      this.filterInstitutionen = null
      this.search = ""
    },
    shortBundesland(shortGuid: string): string {
      let b = this.facade.Bundeslaender.find((el) => {
        return el.ShortGuid == shortGuid
      })
      return b.Kuerzel || b.Name
    },
    shortInstitution(shortGuid: string): string {
      let b = this.facade.Institutionen.find((el) => {
        return el.ShortGuid == shortGuid
      })
      return b.Kuerzel || b.Name
    },
    ToInstitution: function (shortGuid: string): string {
      let i = this.facade.Institutionen.find((el) => {
        return el.ShortGuid == shortGuid
      })
      if (i == undefined) return shortGuid
      return i.Name
    },

    ToBundeslandFromInstitution: function (shortGuid: string): string {
      let i = this.facade.Institutionen.find((el) => {
        return el.ShortGuid == shortGuid
      })
      if (i == null || i.BundeslandRef == null) return ""

      shortGuid = i.BundeslandRef.ShortGuid
      let b = this.facade.Bundeslaender.find((el) => {
        return el.ShortGuid == shortGuid
      })
      if (b == null) {
        return ""
      }
      return b.Name
    },
  },
  data() {
    return {
      search: "",
      selected: [],
      filterBundesländer: null,
      filterInstitutionen: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "AnzeigeName",
        },
        {
          text: "Institution",
          align: "start",
          sortable: true,
          value: "InstitutionRef",
          sort: (a, b) => {
            if (a == null || b == null) return 0
            a = a.ShortGuid
            b = b.ShortGuid
            if (a == null || b == null) return 0
            let aaa = (this as any).ToInstitution(a)
            let bbb = (this as any).ToInstitution(b)
            return aaa.localeCompare(bbb)
          },
        },
        {
          text: "Bundesland",
          align: "start",
          sortable: true,

          value: "InstitutionRef.ShortGuid",
          sort: (a, b) => {
            if (a == null || b == null) return 0
            a = (this as any).ToBundeslandFromInstitution(a)
            b = (this as any).ToBundeslandFromInstitution(b)

            return a.localeCompare(b)
          },
        },
        {
          text: "Aktionen",
          value: "ShortGuid",
          sortable: false,
          width: "10px",
          align: this.modus != 0 ? " d-none" : " djj",
        },
      ],
    }
  },
});
