












import { GeoGlobals } from "../GeoGlobals"
import Config from "../Config"
import {
  DtpSeite,
  DtpFilter,
  DtpSortierung,
  DtpInfo,
  DtoSortDirection
} from "../FacadeVorgangListe"

declare const $geoGlobals: GeoGlobals
import Vue from "vue"
import ListeControl from "../components/ListeControl.vue"
export default Vue.extend({
  components: {
    ListeControl
  },
  destroyed() {
  },
  mounted() {
    this.$event.$emit("show-buttons", "E")
    document.title = $geoGlobals.Title
  }
  ,
  data() {
    return {
      // TODO Hier kann sicherlich vieles weg.
      filter: DtpFilter,
      goto: null,
      selectedMelder: [],
      selectedPrio: [],
      optionsTypen: [],
      optionsPrios: [],
      optionsStatus: [],
      optionsMelder: [],
      optionsVerantwortliche: [],
      optionsProdukte: [],
      optionsZielversionen: [],
      rememberNrSorting: undefined,
      rememberNummerSorting: undefined,
      isLoading: false,
      options: {
        sortBy: ["Nummer"],
        sortDesc: [true]
      },
      liste: {
        AuswahlFilter: {
          Typen: [],
          Prios: []
        }
      },
      entries: []
    }
  },
  methods: {
    resetFilters: function () {
      this.filter = new DtpFilter()
    },
    toDate(d: string): string {
      if (d === null) return ""
      let matches = d.match(/(\d+)/)
      let date = new Date(parseInt(matches[0]))
      return (
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        (date.getFullYear() - 2000)
      )
    },
    shortStatus(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangStatus(shortId)
      return rv[0]
    },
    shortPriorität(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangPriorität(shortId)
      return rv[0]
    },
    shortTyp(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangTyp(shortId)
      return rv[0]
    },
  }
});
