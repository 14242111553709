







import Vue from "vue"

import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals

export default Vue.extend({
  name: "Intern",
  beforeRouteLeave(to, from, next) {
    if (to.name === "login") {
      clearTimeout($geoGlobals.refreshTimer)
      delete $geoGlobals.refreshTimer

      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      let post = this.$http
        .post(
          $geoGlobals.ServerAddress + "InvalidateRefreshTokens",
          { responseType: "json" },
          auth
        )

      post.then(result => {
        delete $geoGlobals.jwtoken
        next()
      }, error => {
        next()
      })
    } else {
      next()
    }

  },

  mounted() {
    console.info("loading config")

    const auth = {
      headers: {
        Authorization: "Bearer " + $geoGlobals.jwtoken
      }
    }
    let hash: number = 0

    if ($geoGlobals.configData != undefined && $geoGlobals.configData?.HashCode && sessionStorage.getItem("config") !== null) {
      hash = $geoGlobals.configData.HashCode
    } else if (sessionStorage.getItem("config") !== null && sessionStorage.getItem("config") !== "null") {

      let text = sessionStorage.getItem("config")
      let obj = JSON.parse(text)
      if (obj["Config"] != undefined) {
        $geoGlobals.configData = JSON.parse(text).Config
        hash = $geoGlobals.configData.HashCode
      } else {
      }
    } else {
    }
    const postData = {
      currentHashCode: hash
    }

    let post = this.$http.post(
      $geoGlobals.ServerAddress + "ConfigCacheLaden",
      postData,
      auth
    )
    post.then(
      result => {
        if (result.data.d.Message !== null) {
          delete $geoGlobals.jwtoken
          this.loggedIn = false
          this.$event.$emit("global-snackbar", result.data.d.Message)
          this.$router.push("/login")
          return
        }
        if (result.data.d.NeedRefresh === false) {
          console.info("kept old config", $geoGlobals.configData)
        } else {
          $geoGlobals.configData = result.data.d.Config
          sessionStorage.setItem("config", JSON.stringify(result.data.d))
          console.info("config loaded", $geoGlobals.configData)
        }
      },
      error => {
        this.$event.$emit(
          "global-snackbar",
          "Konfiguration konnte nicht geladen werden"
        )
      }
    )
  }
});
