









































































































import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
var cloneDeep = require("lodash.clonedeep")

import Vue from "vue"
import { DtoState, Person } from "../FacadeVorgangListe"
export default Vue.extend({
  props: {
    facade: null,
    person: Person,
    personNeu: {
      type: Number,
      default: 0,
    },
  },
  mounted: function () {
    if (this.facade !== null) {
      // Bundesland setzen
      let institut = this.facade.Institutionen.find(
        (el) => el.ShortGuid == this.person.InstitutionRef.ShortGuid
      )
      if (institut !== null && institut !== undefined) {
        this.bundesland = institut.BundeslandRef.ShortGuid
      }
    }
  },
  data() {
    return {
      anredeItems: ["Herr", "Frau"],
      showDialogInstitution: false,
      institutDialogMode: "neu" as ("neu" | "edit"),
      bundesland: "",
      tempInstitution: null,
      subdialogTitle: "Institution editieren",
    }
  },
  methods: {
    Cancel: function () {
      this.data.showPersonDialog = false
    },
    ShowDialogInstitution: function (mode: "neu" | "edit") {
      this.institutDialogMode = mode
      if (mode == "neu") {
        const auth = {
          headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
        }
        let post = this.$http.post(
          $geoGlobals.ServerAddress + "InstitutionNeu",
          {},
          auth
        )
        post.then(
          (result) => {
            result = result.data.d
            this.tempInstitution = result.Institution
            this.tempInstitution.BundeslandRef = { ShortGuid: null }
            this.showDialogInstitution = true
          },
          (error) => {
            console.error("InstitutionNeu", error)
            this.$event.$emit("global-snackbar", error)
          }
        )
        this.subdialogTitle = "Institution hinzufügen"
      } else {
        this.tempInstitution = cloneDeep(this.aktuelleInstitution)
        this.subdialogTitle = "Institution editieren"
        this.showDialogInstitution = true
      }
    },
    StoreInstitution: function () {
      let index = this.facade.Institutionen.findIndex(
        (el) => el.ShortGuid === this.tempInstitution.ShortGuid
      )

      if (index == -1) {
        this.facade.Institutionen.push(this.tempInstitution)
        this.bundesland = this.tempInstitution.BundeslandRef.ShortGuid

        this.person.InstitutionRef.ShortGuid = this.tempInstitution.ShortGuid
        this.showDialogInstitution = false
        return
      }
      this.bundesland = this.tempInstitution.BundeslandRef.ShortGuid
      this.facade.Institutionen[
        index
      ].Beschreibung = this.tempInstitution.Beschreibung
      this.facade.Institutionen[
        index
      ].BundeslandRef.ShortGuid = this.tempInstitution.BundeslandRef.ShortGuid
      this.facade.Institutionen[index].Name = this.tempInstitution.Name
      this.showDialogInstitution = false
    },
  },
  watch: {
    person: {
      handler: function (val) {
        let institut = this.facade.Institutionen.find(
          (el) => el.ShortGuid == this.person.InstitutionRef.ShortGuid
        )
        if (institut != null) {
          this.bundesland = institut.BundeslandRef.ShortGuid
        }
      },
      deep: true,
    },
    bundesland: function (val) {
      // Institute filtern
    },
    "person.Institution": function (val) {
      // Bundesland setzen
      let institut = this.data.Institutionen.find((el) => el.ShortGuid == val)
      this.bundesland = institut.BundeslandRef.ShortGuid
    },
  },
  computed: {
    sortedBundeslaender: function () {
      var rv = this.facade.Bundeslaender
      rv.sort((v1, v2) => {
        return v1.Name.localeCompare(v2.Name)
      })
      return rv
    },
    aktuelleInstitution: function () {
      return this.facade.Institutionen.find(
        (el) => el.ShortGuid === this.person.InstitutionRef.ShortGuid
      )
    },
    editInstitutionDisabled: function () {
      return this.person.InstitutionRef.ShortGuid == null
    },
    filteredInstitutionen: function () {
      let values: [] = this.facade.Institutionen

      let filter = this.bundesland
      if (values === undefined || filter === undefined) return undefined
      let rvv = values.filter((a: any) => {
        if (filter === null) return true
        // Passende und solche ohne Bundesland
        // Heri möchte zwar, dass man die Institutionen ohne Bundesländer nur sieht, wenn man
        // kein Bundesland ausgewählt hat, allerdings steht die Option nicht zur Verfügung.
        return (
          a.BundeslandRef.ShortGuid === filter ||
          a.BundeslandRef.ShortGuid === null
        )
      })
      return rvv.sort((v1: any, v2: any) => { return v1.Name.localeCompare(v2.Name) })
    },
  },
});
