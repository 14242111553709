export enum DtoExportColumn {
    Nummer = 1,
    ExterneId = 2,
    Typ = 3,
    Prio = 4,
    Status = 5,
    Meldedatum = 6,
    Melder = 7,
    StatusaktionDatum = 8,
    Statusaktion = 9,
    Verantwortlich = 10,
    Produktgruppe = 11,
    Produkt = 12,
    Meldeversion = 13,
    Zielversion = 14,
    Titel = 15,
    Meldung = 16,
    Dokumente = 17,
}

export enum DtoExportTyp {
    // Full bedeutet, dass der Meldungtext nicht abgeschnitten wird.
    Excel = 1,
    Csv = 2,
    ExcelFull = 3,
    CsvFull = 4,
}//enum
export class DtoExportInfo {
    ExportTyp: DtoExportTyp
    ExportColumns: DtoExportColumn[]

    constructor() {
        this.ExportTyp = (window as any).ExcelExport ? DtoExportTyp.ExcelFull : DtoExportTyp.CsvFull
        this.ExportColumns = [
            DtoExportColumn.Nummer,
            DtoExportColumn.ExterneId,
            DtoExportColumn.Produktgruppe,
            DtoExportColumn.Produkt,
            DtoExportColumn.Titel,
            DtoExportColumn.Meldung,
            DtoExportColumn.Meldeversion,
            DtoExportColumn.Zielversion,
            DtoExportColumn.Typ,
            DtoExportColumn.Prio,
            DtoExportColumn.Status,
            DtoExportColumn.Meldedatum,
            DtoExportColumn.Melder,
            DtoExportColumn.StatusaktionDatum,
            DtoExportColumn.Statusaktion,
            DtoExportColumn.Dokumente,
            DtoExportColumn.Verantwortlich
        ]
    }
}//class 

export enum DtoErgebnisTyp {
    VorgangTitel,
    VorgangFremdId,
    VorgangSchlagwort,
    AktionBeschreibung,
}//enum

export class DtpSeite {
    Anforderung: number
    Seite: number
    Anzahl: number
}

export class DtpFilter {
    static Typen: any
    Typen: number[]
    Prios: number[]
    Status: number[]
    MeldedatumBereich: DtpDatumBereich
    Melder: string[]
    LetzteWlBereich: DtpDatumBereich
    Verantwortliche: string[]
    Gruppen: string[]
    Produkte: string[]
    Zielversionen: string[]
    Meldeversionen: string[]
    ExcludeVorgaenge: any[]
    constructor() {
        this.MeldedatumBereich = new DtpDatumBereich()
    }
}

export class DtpDatumBereich {
    From: Date
    To: Date
}

export class Person {
    Login: string
    Anrede: string
    Vorname: string
    Nachname: string
    Telefon: string
    EMail: string
    InstitutionRef: any
    KeineMeldungen: boolean

}

export class DtpSortierung {
    Nummer: DtpInfo
    Typ: DtpInfo
    Prio: DtpInfo
    Status: DtpInfo
    Meldedatum: DtpInfo
    Melder: DtpInfo
    LetzteWl: DtpInfo
    Verantwortlich: DtpInfo
    Produkt: DtpInfo
    Zielversion: DtpInfo
}

export class DtpInfo {
    Order: number
    Direction: DtoSortDirection
}

export enum DtoSortDirection {
    Ascending = 1, Descendung = 2
}


export enum ConflictResolutionType {
    None = 0,
    Merge = 1,
    Overwrite = 2,
    Reload = 3,
}

export enum DtoState {
    None = 0,

    // Objektstatus: Information vom (Server an den) Client an den Server
    New = 1 << 0,
    Removed = 1 << 1,

    // Zugriffsrechte: Information vom Server an den Client
    Edit = 1 << 8,
    Remove = 1 << 9,
    Add = 1 << 10,
}

export enum VorgangStatus {
    Unspezifiziert = 0,
    InBewertung = 1,
    InAnwenderbetreuung = 2,
    InBearbeitung = 3,
    InQualitaetssicherung = 4,
    Pausiert = 5,
    Gekennzeichnet = 6,
    Eingestellt = 7,
    Abgeschlossen = 8,
    InBearbeitungHilfe = 9,
    InQualitaetssicherungHilfe = 10,
    InErstellungReleaseNotes = 11,
}

export enum DtoResultState {
    None = 0,
    Ok = 1,
    Error = 2,
    Conflict = 3,
}//enum

export enum ZustandStatus {
    /// <summary>
    /// UNBESTIMMT
    /// </summary>
    Unspezifiziert = 0,
    Geplant = 1,
    InEntwicklung = 2,
    InAbnahme = 3,
    /// <summary>
    /// AUSGELIEFERT
    /// </summary>
    Freigegeben = 4,
    /// <summary>
    /// SUPPORT EINGESTELLT
    /// </summary>
    Abgekuendigt = 5,
}//enum




export enum Zugriffsrecht {
    //----------------
    // Vorgang --> Bezug zum Produkt/Gruppe
    VorgangSehen = 0,
    VorgangNeu = 1,
    VorgangLoeschen = 2,
    /// <summary> Titel, Typ, Priorität und Meldung</summary>
    VorgangEditieren = 3,
    VorgangEditierenFremdId = 7,
    VorgangEditierenZielVersion = 8,
    VorgangEditierenMeldeVersion = 9,
    VorgangEditierenSchlagworte = 10,
    VorgangEditierenInteressierte = 11,
    VorgangEditierenStellvertretener = 12,

    //----------------
    // Aktion --> Bezug zum Produkt/Gruppe
    AbschliessenSehen = 15,
    AbschliessenNeu = 16,
    AbschliessenLoeschen = 17,
    AbschliessenEditieren = 18,

    DokumentierenSehen = 20,
    DokumentierenNeu = 21,
    DokumentierenLoeschen = 22,
    DokumentierenEditieren = 23,

    DuplizierenSehen = 25,
    DuplizierenNeu = 26,
    DuplizierenLoeschen = 27,
    DuplizierenEditieren = 28,

    EinstellenSehen = 30,
    EinstellenNeu = 31,
    EinstellenLoeschen = 32,
    EinstellenEditieren = 33,

    KennzeichnenSehen = 35,
    KennzeichnenNeu = 36,
    KennzeichnenLoeschen = 37,
    KennzeichnenEditieren = 38,

    KommentierenSehen = 40,
    KommentierenNeu = 41,
    KommentierenLoeschen = 42,
    KommentierenEditieren = 43,

    PausierenSehen = 45,
    PausierenNeu = 46,
    PausierenLoeschen = 47,
    PausierenEditieren = 48,

    ReferenzierenSehen = 50,
    ReferenzierenNeu = 51,
    ReferenzierenLoeschen = 52,
    ReferenzierenEditieren = 53,

    WeiterleitenSehen = 55,
    WeiterleitenNeu = 56,
    WeiterleitenLoeschen = 57,
    WeiterleitenEditieren = 58,

    //----------------
    // Person --> Kein Produktbezug
    PersonSehen = 65,
    PersonNeu = 66,
    PersonLoeschen = 67,
    PersonEditieren = 68,

    //----------------
    // Institution --> Kein Produktbezug
    //InstitutionSehen = 70, Recht "InstitutionSehen" an das Recht "PersonSehen" gekoppelt
    InstitutionNeu = 71,
    InstitutionLoeschen = 72,
    InstitutionEditieren = 73,

    //----------------
    // Gruppe
    GruppeSehen = 75,
    GruppeNeu = 76,
    GruppeLoeschen = 77,
    GruppeEditieren = 78,

    //----------------
    // Produkt 
    //ProduktSehen = 80, Recht "ProduktSehen" an das Recht "GruppeSehen" gekoppelt
    ProduktNeu = 81, // KEIN Bezug zur Gruppe
    ProduktLoeschen = 82, // Bezug zur Gruppe
    ProduktEditieren = 83, // Bezug zur Gruppe

    //----------------
    // Konfiguration --> Kein Produktbezug
    KonfiguratonVerwalten = 85,
    GruppenVerwalten = 86,
    ProdukteVerwalten = 87,
    RollenVerwalten = 88,
    RechteVerwalten = 89,

    //================
    // Maximum gesamt
    Maximum = RechteVerwalten,
}//enum