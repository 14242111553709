












































import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
import Vue from "vue"
const sha256 = require("simple-sha256")

export default Vue.extend({
  mounted() {
    this.guid = decodeURIComponent(this.$route.params.guid)

    const postData = {
      guidText: this.guid
    }
    let post = this.$http.post(
      $geoGlobals.ServerAddress + "ValidateRecoveryGuid",
      postData
    )
    post.then(
      result => {
        result = result.data.d
        this.feedback = result.Message
      },
      error => {
        console.error("ValidateRecoveryGuid", error)
      }
    )
  },
  data() {
    return {
      feedback: "",
      guid: "",
      show1: false,
      show2: false,
      password: "",
      passwordRepeat: "",
      rules: {
        required: value => !!value || "Eingabe notwendig."
      }
    }
  },
  computed: {
    showFeedback: function () {
      if (this.feedback == null) return false
      return this.feedback.length > 0
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.passwordRepeat ||
        "Kennwörter sind nicht identisch"
    }
  },
  methods: {
    SetPassword: function () {
      if (!this.$refs.form.validate()) {
        this.$event.$emit("global-snackbar", "Formulareingaben nicht gültig")
        return
      }

      const postData = {
        sha256PwdHash: sha256.sync(this.password),
        newPasswordText: this.guid
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "SetRecoveryPassword",
        postData
      )
      post.then(result => {
        result = result.data.d
        this.$event.$emit("global-snackbar", result.Message)
        if (result.Result == 0) this.$router.push("/login")
        return
      })
    }
  }
});
