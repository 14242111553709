































































































import { GeoGlobals } from "../GeoGlobals"
import PersonNeuEdit from "../components/PersonNeuEdit.vue"
import Vue from "vue"
import { ConflictResolutionType, DtoState, DtoResultState } from "../FacadeVorgangListe"
const jwt = require("jsonwebtoken")
declare const $geoGlobals: GeoGlobals
const sha256 = require("simple-sha256")
const md5 = require("md5")

export default Vue.extend({
  mounted() {
    this.$event.$emit("show-buttons", "")
    this.ReloadPerson()
    this.ReloadBenachrichtigungen()
  },
  components: { PersonNeuEdit },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required: value => !!value || "Eingabe notwendig."
      },
      facade: null,
      person: null,
      person2: null,
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.newPasswordRepeat ||
        "Kennwörter sind nicht identisch"
    }
  },
  methods: {
    SaveBenachrichtigungen: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      const p = this.person2.Person


      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlSpeichern",
        {
          dtoPerson: p,
          dtoInstitutionen: this.facade.Institutionen,
          conflictResolution: ConflictResolutionType.Overwrite
        },
        auth
      )

      post.then(
        result => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.$event.$emit("global-snackbar", result.Message || "Speichern erfolgreich")
          } else {
            console.error("PersonControlSpeichern", result.Message || "Speichern fehlgeschlagen")
            this.$event.$emit("global-snackbar", result.Message || "Speichern fehlgeschlagen")
          }
        },
        error => {
          console.error("PersonControlSpeichern", error || "Speichern fehlgeschlagen")
          this.$event.$emit("global-snackbar", error || "Speichern fehlgeschlagen")
        }
      )
    },
    SavePerson: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      const p = this.person.Person

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlSpeichern",
        {
          dtoPerson: p,
          dtoInstitutionen: this.facade.Institutionen,
          conflictResolution: ConflictResolutionType.Overwrite
        },
        auth
      )

      post.then(
        result => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.$event.$emit("global-snackbar", result.Message || "Speichern erfolgreich")
          }
          else {
            this.$event.$emit("global-snackbar", result.Message || "Speichern fehlgeschlagen")
            console.error("PersonControlSpeichern", result.Message || "Speichern fehlgeschlagen")
          }

        },
        error => {
          console.error("PersonControlSpeichern", error || "Speichern fehlgeschlagen")
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    ReloadBenachrichtigungen: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      const decoded = jwt.decode($geoGlobals.jwtoken)
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonLaden",
        {
          dtoPersonRef: { ShortGuid: decoded.Sub },
          conflictResolution: ConflictResolutionType.Overwrite
        },
        auth
      )
      post.then(
        result => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.person2 = result
          } else {
            this.$event.$emit("global-snackbar", result.Message)
            console.warn("ReloadBenachrichtigungen()", result.Message)
          }
        },
        error => {
          console.error("ReloadBenachrichtigungen()", error)
        }
      )
    },
    ReloadPerson: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      const decoded = jwt.decode($geoGlobals.jwtoken)
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonControlLaden",
        {},
        auth
      )
      post.then(
        result => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.facade = result.data.d
            this.facade.Institutionen.forEach(element => {
              if (element.BundeslandRef == null)
                element.BundeslandRef = {}
            })
          } else {
            console.error("PersonControlLaden", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        error => {
          console.error("PersonControlLaden", error)
          this.$event.$emit("global-snackbar", error)
        }
      )

      post = this.$http.post(
        $geoGlobals.ServerAddress + "PersonLaden",
        {
          dtoPersonRef: { ShortGuid: decoded.Sub },
          conflictResolution: ConflictResolutionType.Overwrite
        },
        auth
      )
      post.then(
        result => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.person = result.data.d
          } else {
            console.error("PersonLaden", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        error => {
          console.error("PersonLaden", error)
          this.$event.$emit("global-snackbar", error)
        }
      )


    },
    SetPassword: function () {
      if (!this.$refs.formPassword.validate()) {
        this.$event.$emit("global-snackbar", "Formulareingaben nicht gültig")
        return
      }

      const postData = {
        sha256PwdHash: sha256.sync(this.oldPassword),
        md5PwdHash: md5(this.oldPassword),
        newSha256PwdHash: sha256.sync(this.newPassword)
      }
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ChangePassword",
        postData,
        auth
      )
      post.then(result => {
        result = result.data.d
        if (result.Status == DtoResultState.Ok) {
          this.$event.$emit("global-snackbar", result.Message || "Speichern erfolgreich")
          this.$refs.formPassword.reset()
        }
        else {
          this.$event.$emit("global-snackbar", result.Message || "Speichern fehlgeschlagen")
          console.error("ChangePassword", result.Message || "Speichern fehlgeschlagen")
        }


      }, error => {
        console.error("ChangePassword", error)
        this.$event.$emit("global-snackbar", error)
      })
    }
  }
});
