


























































































































































































import Vue from "vue"
import {
  ConflictResolutionType,
  DtoResultState,
  DtoState,
} from "../FacadeVorgangListe"
import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
import Config from "../Config"
const config = new Config()

var cloneDeep = require("lodash.clonedeep")

export default Vue.extend({
  mounted() {
    this.GetList()
  },

  computed: {
    visibleSRegistrierungen: function () {
      let rv = this.facadeGruppe.Gruppe.SRegistrierungen
      rv = rv.filter(a => (a.State & DtoState.Removed) == 0)
      return rv
    },
    visibleSchlagwortarten: function () {
      let rv = this.facadeGruppe.Gruppe.SchlagwortArten
      rv = rv.filter(a => (a.State & DtoState.Removed) == 0)
      return rv
    },
    newGruppeDisabled: function () {
      return !((this.facade.Gruppen.State & DtoState.Add) == DtoState.Add)
    },
    newSchlagwortDisabled: function () {
      return false
    },
    showDialogGruppeEditieren: function () {
      return this.facadeGruppe != null
    },
    showDialogSchlagwortArtEditieren: function () {
      return this.schlagwortArt != null
    },
    showDialogStellenregistrierungEditieren: function () {
      return this.stellenregistrierung != null
    },
    allVorgangstatus: function () {
      return config.data.VorgangStatus
    },
    allZustandstatus: function () {
      return config.data.ZustandStatus
    },
  },

  methods: {
    ToKuerzel: function (item) {
      if (item.Kuerzel != null && item.Kuerzel.length > 0)
        return item.Kuerzel
      return item.Name
    },
    allFolgestatus: function () {
      const config = new Config()
      /* Die Nutzung von CfgWlFolgestatus ist hier falsch. Welche Folgestatus angeboten werden, sollte
      davon abhängen, welche in dieser Gruppe erlaubt sind. */
      let rv = this.facadeGruppe.Gruppe.CfgWlFolgestatus
      let xxx = this.facadeGruppe.Gruppe.GenutztVorgangStatusRefs.map(a => { return a.ShortGuid })
      let datan = config.data.VorgangStatus.filter(a => {
        return xxx.indexOf(a.ShortGuid)!==-1
      }).map( a => {return a.Kuerzel})
      let rvx = rv.filter(c => {
        return xxx.indexOf(c.ShortGuid)!==-1
      })
      rv = config.data.Folgestatus.filter((c) => {
        let a = c.GruppeRef.ShortGuid
        let b = this.facadeGruppe.Gruppe.ShortGuid
        return a==b
      })

      let rvxx = this.facadeGruppe.Gruppe.CfgWlFolgestatus.filter(c => {
        return xxx.indexOf(c.ShortGuid)!==-1
      })

      
      rv = rv.filter(c => {
        return datan.indexOf(c.Kuerzel)!==-1
      })
      rv=rv
      rv.forEach(element => {
        if (element.Kuerzel != null && element.Kuerzel.length > 0) {
          element.Langname = element.Name + " (" + element.Kuerzel + ")"
        }
      })

      return rv
    },
    ResetGruppe: function () {
      this.clickEditGruppe(this.facadeGruppe.Gruppe.ShortGuid)
    },
    StoreSchlagwort: function () {
      // Look for it, maybe it's new, maybe it's changed.
      let index = this.facadeGruppe.Gruppe.SchlagwortArten.indexOf(this.schlagwortArt)
      if (index == -1) {
        this.facadeGruppe.Gruppe.SchlagwortArten.push(this.schlagwortArt)
      } else {
        this.facadeGruppe.Gruppe.SchlagwortArten[index] = this.schlagwortArt
      }
      this.schlagwortArt = null

    },
    StoreStellenregistrierung: function () {
      // Look for it, maybe it's new, maybe it's changed.
      let found = this.facadeGruppe.Gruppe.SRegistrierungen.find(
        sreg => {
          return sreg.ShortGuid == this.stellenregistrierung.ShortGuid
        })
      let index = -1
      if (found != undefined)
        index = this.facadeGruppe.Gruppe.SRegistrierungen.indexOf(found)
      if (index == -1) {
        this.facadeGruppe.Gruppe.SRegistrierungen.push(this.stellenregistrierung)
      } else {
        this.facadeGruppe.Gruppe.SRegistrierungen[index] = cloneDeep(this.stellenregistrierung)
        // Force update of the data table
        this.facadeGruppe.Gruppe.SRegistrierungen.push("")
        this.facadeGruppe.Gruppe.SRegistrierungen.pop()
      }
      this.stellenregistrierung = null

    },
    StoreGruppe: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      this.facadeGruppe.Gruppe.SRegistrierungen.forEach(sreg => {
        /*
        // Der Server will korrekte Informationen über neu, geändert, gelöscht.
        let currentSituation = sreg.CfgFolgestatusRefs.map(a => a.ShortGuid)
        sreg.CfgFolgestatusRefs.forEach(element => {
          element.State = DtoState.Removed
          alert("potentiell gelöscht")
        })*/
        sreg.CfgFolgestatusRefs = []
        if (sreg.selected != null) {
          sreg.selected.forEach(id => {
            let neu = {
              ShortGuid: id,
              State: 0
            }
            sreg.CfgFolgestatusRefs.push(neu)
          })
        }
      })

      this.facadeGruppe.Gruppe.GenutztVorgangStatusRefs = this.selectedVorgangstatus
      this.facadeGruppe.Gruppe.GenutztZustandStatusRefs = this.selectedZustandstatus

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "GruppeDialogSpeichern",
        {
          dtoGruppeDlg: this.facadeGruppe.Gruppe,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )
      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            {

              // Config neu laden
              console.info("Reloading config, because adding/changing a group might influence config")
              let postLoad = this.$http.post(
                $geoGlobals.ServerAddress + "ConfigCacheLaden",
                { currentHashCode: -1 },
                auth
              )
              postLoad.then(
                (result) => {
                  result = result.data.d
                  $geoGlobals.configData = result.Config
                },
                (error) => {
                  console.error("ConfigCacheLaden", result.Message)
                  this.$event.$emit("global-snackbar", result.Message)
                }
              )
            }
            this.facadeGruppe = null
            this.GetList()
          } else {
            console.error("GruppeDialogSpeichern", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("GruppeDialogSpeichern", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },

    clickDeleteSchlagwort: function (id) {
      let schlagwortArt = this.facadeGruppe.Gruppe.SchlagwortArten.find(e => e.ShortGuid == id)
      if ((schlagwortArt.State & DtoState.New) == 0) {
        // ein alter Eintrag
        schlagwortArt.State |= DtoState.Removed
      } else {
        // ein neuer Eintrag
        this.facadeGruppe.Gruppe.SchlagwortArten =
          this.facadeGruppe.Gruppe.SchlagwortArten.filter((a) => {
            return a.ShortGuid != id
          })
      }
    },
    clickDeleteStellenregistrierung: function (id) {
      let sreg = this.facadeGruppe.Gruppe.SRegistrierungen.find(e => e.ShortGuid == id)
      if ((sreg.State & DtoState.New) == 0) {
        // ein alter Eintrag
        sreg.State |= DtoState.Removed
      } else {
        // ein neuer Eintrag
        this.facadeGruppe.Gruppe.SRegistrierungen =
          this.facadeGruppe.Gruppe.SRegistrierungen.filter((a) => {
            return a.ShortGuid != id
          })
      }
    },

    clickDeleteGruppe: function (id) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }

      let gruppe1: any = {}
      gruppe1.ShortGuid = id
      gruppe1.State = DtoState.None

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "GruppeLoeschen",
        {
          dtoGruppeRef: gruppe1,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.GetList()
          } else {
            console.error("GruppeLoeschen", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("GruppeLoeschen", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
    clickEditGruppe: function (id: string | null) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post
      if (id == null) {
        this.titleGruppe = "Produktgruppe hinzufügen"
        post = this.$http.post(
          $geoGlobals.ServerAddress + "GruppeNeu",
          {},
          auth
        )
      } else {
        this.titleGruppe = "Produktgruppe editieren"
        let gruppe1: any = {}
        gruppe1.ShortGuid = id
        gruppe1.State = DtoState.None

        post = this.$http.post(
          $geoGlobals.ServerAddress + "GruppeLaden",
          { dtoGruppeRef: gruppe1 },
          auth
        )
      }
      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.selectedVorgangstatus = result.Gruppe.GenutztVorgangStatusRefs
            this.selectedZustandstatus = result.Gruppe.GenutztZustandStatusRefs
            this.facadeGruppe = result

            this.facadeGruppe.Gruppe.SRegistrierungen.forEach(sreg => {
              sreg.selected = []
              let sel = sreg.CfgFolgestatusRefs.forEach(el => {
                sreg.selected.push(el.ShortGuid)
              })
            })
          } else {
            console.error("clickEditGruppe()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("clickEditGruppe()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },

    clickEditSchlagwort: function (id: string | null) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post
      if (id == null) {
        this.titleSchlagwort = "Schlagwort hinzufügen"
        post = this.$http.post(
          $geoGlobals.ServerAddress + "SchlagwortArtNeu",
          {},
          auth
        )

        post.then(
          (result) => {
            result = result.data.d
            if (result.Status == DtoResultState.Ok) {
              this.schlagwortArt = result.SchlagwortArt
            } else {
              console.error("SchlagwortNeu", result.Message)
              this.$event.$emit("global-snackbar", result.Message)
            }
          },
          (error) => {
            console.error("SchlagwortNeu", error)
            this.$event.$emit("global-snackbar", error)
          }
        )
      } else {
        this.titleSchlagwort = "Schlagwort editieren"
        this.schlagwortArt = this.facadeGruppe.Gruppe.SchlagwortArten.find(schlag => schlag.ShortGuid == id)
      }
    },
    clickEditStellenregistrierung: function (id: string | null) {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post
      if (id == null) {
        this.titleStellenregistrierung = "Stellenregistrierung hinzufügen"
        post = this.$http.post(
          $geoGlobals.ServerAddress + "StelleRegNeu",
          {},
          auth
        )

        post.then(
          (result) => {
            result = result.data.d
            if (result.Status == DtoResultState.Ok) {
              this.stellenregistrierung = result.StelleReg
            } else {
              console.error("StelleRegNeu", result.Message)
              this.$event.$emit("global-snackbar", result.Message)
            }
          },
          (error) => {
            console.error("StelleRegNeu", error)
            this.$event.$emit("global-snackbar", error)
          }
        )
      } else {
        this.titleStellenregistrierung = "Stellenregistrierung editieren"
        this.stellenregistrierung = cloneDeep(this.facadeGruppe.Gruppe.SRegistrierungen.find(schlag => schlag.ShortGuid == id))
      }
    },
    clickNewGruppe: function () {
      this.clickEditGruppe(null)
    },
    clickNewSchlagwort: function () {
      this.clickEditSchlagwort(null)
    },
    clickNewStellenregistrierung: function () {
      this.clickEditStellenregistrierung(null)
    },
    GetList: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "GruppenVerwaltenDialogLaden",
        {},
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.facade = result
          } else {
            console.error("GetList()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("GetList()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
  },
  data() {
    return {
      facade: null,
      facadeGruppe: null,
      schlagwortArt: null,
      stellenregistrierung: null,
      selectedVorgangstatus: [],
      selectedZustandstatus: [],
      titleGruppe: "Hallo",
      titleSchlagwort: "",
      titleStellenregistrierung: "",
      sregistrierungenHeaders: [
        {
          text: "Name",
          value: "Name",
        },
        {
          text: "Beschreibung",
          align: "start",
          value: "Beschreibung",
          sortable: true,
        },
        {
          text: "Folgestatus",
          align: "start",
          value: "selected",
          sortable: false
        }, {
          text: "Aktionen",
          align: "start",
          value: "ShortGuid",
          sortable: false
        },
      ],
      vorgangstatusHeaders: [
        {
          text: "Name",
          value: "Name",
        },

      ],
      schlagwortartenHeaders: [
        {
          text: "Schlagwort",
          value: "Name"
        }, {
          text: "Kürzel",
          align: "start",
          value: "Kuerzel",
          sortable: true,
        },
        {
          text: "Beschreibung",
          align: "start",
          value: "Beschreibung",
          sortable: true,
        },
        {
          text: "Aktionen",
          align: "start",
          value: "ShortGuid",
          sortable: false
        }
      ],
      gruppenHeaders: [
        {
          text: "Produktgruppe",
          align: "start",
          value: "Name",
          sortable: true,
        },
        {
          text: "Kürzel",
          align: "start",
          value: "Kuerzel",
          sortable: true,
        },
        {
          text: "Aktionen",
          align: "start",
          value: "ShortGuid",
          sortable: false
        },
      ],
    }
  },
});
