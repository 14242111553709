









































































































































import { GeoGlobals } from "../GeoGlobals"
import {
  DtpSeite,
  DtpSortierung,
  DtpInfo,
  DtoSortDirection,
  DtoResultState,
  DtoExportTyp,
  DtoState, DtoExportInfo
} from "../FacadeVorgangListe"

import Vue from "vue"
import Config from "../Config"

declare const $geoGlobals: GeoGlobals

import { DtpFilter } from '../FacadeVorgangListe'

export default Vue.extend({
  computed: {
    readableVon() {
      if (this.filter.MeldedatumBereich.From == null || this.filter.MeldedatumBereich.From == "")
        return null
      if (typeof this.filter.MeldedatumBereich.From == "string") {
        let v = new Date(this.filter.MeldedatumBereich.From)
        return this.ToDateText(v)
      }

      return this.ToDateText(this.filter.MeldedatumBereich.From)
    },

    readableBis() {
      if (this.filter.MeldedatumBereich.To == null || this.filter.MeldedatumBereich.To == "")
        return null
      if (typeof this.filter.MeldedatumBereich.To == "string") {
        let v = new Date(this.filter.MeldedatumBereich.To)
        return this.ToDateText(v)
      }

      return this.ToDateText(this.filter.MeldedatumBereich.To)
    },
  },
  methods: {
    clickClearVon(): void {
      this.filter.MeldedatumBereich.From = null
    },
    clickClearBis(): void {
      this.filter.MeldedatumBereich.To = null
    },
    ToShortStatus(shortId: number): string {
      let config = new Config()
      let rv = config.getVorgangStatus(shortId)
      return rv[0] || rv[1]
    },
    ToDateText(date): string {

      if (typeof date == "string") {
        let matches = date.match(/(\d+)/)
        date = new Date(parseInt(matches[0]))
      }

      let rv = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      return rv
    },
    shortStatus(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangStatus(shortId)
      return rv[0]
    },
    shortTyp(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangTyp(shortId)
      return rv[0]
    },
    shortPriorität(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangPriorität(shortId)
      return rv[0]
    },
    handleButtons: function (data) {
      if (data == "export") {
        let worte = this.suchtext.split(" ")
        let suchstring = { Suchworte: worte }
        let exinfo = new DtoExportInfo()
        this.$http
          .post(
            $geoGlobals.ServerAddress + "VorgangListeExportieren",
            {
              dtoFilter: this.filter,
              dtoSuchausdruck: suchstring,
              dtoExportInfo: exinfo,
            },
            {
              headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
            }
          )
          .then(
            (result) => {
              result = result.data.d
              if (result.Status == DtoResultState.Ok) {
                const url = $geoGlobals.ServerAddress.replace(
                  "LisaSprService.asmx/",
                  "LisaSprExport.ashx?fileName=" +
                  encodeURIComponent(result.ExportDateiname)
                )
                window.open(url, "_blank")
              } else {
                console.error("VorgangListeExportieren", result.Message)
                this.$event.$emit("global-snackbar", result.Message)
              }
            },
            (error) => {
              console.error("VorgangListeExportieren", error)
              this.$event.$emit("global-snackbar", error)
            }
          )
      }
    },
    labelAktion: function (item) {
      const lastIndex = item.AktionKlasse.lastIndexOf(".")
      let config = new Config()
      let xxx = config.getAktion(item.AktionKlasse)
      let aktionName = xxx[0]
      return aktionName + "/" + this.toDateTime(item.AktionZeitpunkt)
    },
    toDateTime(d: string): string {
      if (d === null) return ""
      let matches = d.match(/(\d+)/)
      let date = new Date(parseInt(matches[0]))
      return (
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        (date.getFullYear() - 2000) +
        " " +
        date.getHours() +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      )
    },
    resetFilters: function () {
      this.filter = new DtpFilter()
      this.suchtext = ""
      this.getDataFromApi()
    },
    initializeFilter: function () {
      this.isLoading++
      if (sessionStorage.getItem("config") === null) {
        setTimeout(this.getDataFromApi, 100)
        return
      }
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      this.$http
        .post(
          $geoGlobals.ServerAddress + "VorgangFilterLaden",
          {
            dtoFilter: this.filter,
          },
          auth
        )
        .then(
          (result) => {
            const rv = result.data.d
            if (rv.Status == DtoResultState.Ok) {
              if (rv.Anforderung !== this.requestCounter) {
                console.info(
                  "Response (" +
                  rv.Anforderung +
                  ") verworfen, warte auf (" +
                  this.requestCounter +
                  ")"
                )
                return
              }
              const config = new Config()
              this.optionsTypen = []
              if (rv.AuswahlFilter.Typen != null) {
                rv.AuswahlFilter.Typen.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangTyp(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsTypen.push(entry)
                })
                this.optionsTypen.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }

              this.optionsPrios = []
              if (rv.AuswahlFilter.Prios != null) {
                rv.AuswahlFilter.Prios.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangPriorität(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsPrios.push(entry)
                })
                this.optionsPrios.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }

              this.optionsStatus = []
              if (rv.AuswahlFilter.Status != null) {
                rv.AuswahlFilter.Status.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangStatus(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsStatus.push(entry)
                })
                this.optionsStatus.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }
              this.optionsMelder = rv.AuswahlFilter.Melder || []
              this.optionsMelder.sort((v1, v2) => {
                return v1 > v2
              })

              this.optionsVerantwortliche =
                rv.AuswahlFilter.Verantwortliche || []
              this.optionsVerantwortliche.sort((v1, v2) => {
                return v1 > v2
              })

              this.optionsGruppen = rv.AuswahlFilter.Gruppen || []
              this.optionsGruppen.sort((v1, v2) => {
                return v1 > v2
              })

              this.optionsProdukte = rv.AuswahlFilter.Produkte || []
              this.optionsProdukte.sort((v1, v2) => {
                return v1 > v2
              })

              this.optionsZielversionen = rv.AuswahlFilter.Zielversionen || []
              this.optionsZielversionen.sort((v1, v2) => {
                return v1 > v2
              })
              this.optionsZielversionen = this.optionsZielversionen.map(a => {
                let n = {
                  wert: a
                }
                return n
              })

              this.optionsMeldeversionen = rv.AuswahlFilter.Meldeversionen || []
              this.optionsMeldeversionen.sort((v1, v2) => {
                return v1 > v2
              })

              // Eindeutigen Key spendieren, um Vuetify-Warnungen zu unterbinden
              let worte = this.suchtext.split(" ")
              this.entries.forEach(function (el, idx) {
                el.key = idx
                worte.forEach(function (wort) {
                  let regex = new RegExp("(" + wort + ")", "gim")
                  el.Text = el.Text.replace(regex, "<mark>$1</mark>")
                })
              })
              this.isLoading--
            } else {
              console.error("initializeFilter", rv.Message)
              this.$event.$emit("global-snackbar", rv.Message)
              this.isLoading--
            }
          },
          (error) => {
            console.error("initializeFilter", error)
            this.$event.$emit("global-snackbar", error)
            this.isLoading--
          }
        )
    },
    getDataFromApi: function () {
      this.isLoading++
      if (sessionStorage.getItem("config") === null) {
        setTimeout(this.getDataFromApi, 100)
        return
      }
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      localStorage.setItem("SearchFilter", JSON.stringify(this.filter))
      localStorage.setItem("SearchText", this.suchtext)

      if (this.suchtext == null) this.suchtext = ""
      let worte = this.suchtext.split(" ")

      let suchstring = { Suchworte: worte }
      this.$http
        .post(
          $geoGlobals.ServerAddress + "VorgangSucheDurchfuehren",
          {
            dtoFilter: this.filter,
            dtoSuchausdruck: suchstring,
          },
          auth
        )
        .then(
          (result) => {
            const rv = result.data.d
            if (rv.Status == DtoResultState.Ok) {
              if (rv.Anforderung !== this.requestCounter) {
                console.info(
                  "Response (" +
                  rv.Anforderung +
                  ") verworfen, warte auf (" +
                  this.requestCounter +
                  ")"
                )
                return
              }
              this.liste = rv
              this.entries = rv.Liste
              this.totalEntries = rv.Gesamtanzahl

              const config = new Config()
              this.optionsTypen = []
              if (rv.AuswahlFilter.Typen != null) {
                rv.AuswahlFilter.Typen.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangTyp(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsTypen.push(entry)
                })
                this.optionsTypen.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }
              if (this.filter.Typen != undefined) {
                this.filter.Typen = this.filter.Typen.filter((a) => {
                  return this.optionsTypen.includes(a)
                })
              }

              this.optionsPrios = []
              if (rv.AuswahlFilter.Prios != null) {
                rv.AuswahlFilter.Prios.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangPriorität(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsPrios.push(entry)
                })
                this.optionsPrios.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }
              if (this.filter.Prios != undefined) {
                this.filter.Prios = this.filter.Prios.filter((a) => {
                  return this.optionsPrios.includes(a)
                })
              }

              this.optionsStatus = []
              if (rv.AuswahlFilter.Status != null) {
                rv.AuswahlFilter.Status.forEach((typ) => {
                  let entry: any = {}
                  entry.id = typ
                  const p = config.getVorgangStatus(typ)
                  entry.label = p[1] + " (" + p[0] + ")"
                  this.optionsStatus.push(entry)
                })
                this.optionsStatus.sort((v1, v2) => {
                  return v1.label > v2.label
                })
              }
              if (this.filter.Status != undefined) {
                this.filter.Status = this.filter.Status.filter((a) => {
                  return this.optionsStatus.includes(a)
                })
              }

              this.optionsMelder = rv.AuswahlFilter.Melder || []
              this.optionsMelder.sort((v1, v2) => {
                return v1 > v2
              })
              if (this.filter.Melder != undefined) {
                this.filter.Melder = this.filter.Melder.filter((a) => {
                  return this.optionsMelder.includes(a)
                })
              }

              this.optionsVerantwortliche =
                rv.AuswahlFilter.Verantwortliche || []
              this.optionsVerantwortliche.sort((v1, v2) => {
                return v1 > v2
              })
              if (this.filter.Verantwortliche != undefined) {
                this.filter.Verantwortliche = this.filter.Verantwortliche.filter(
                  (a) => {
                    return this.optionsVerantwortliche.includes(a)
                  }
                )
              }

              this.optionsGruppen = rv.AuswahlFilter.Gruppen || []
              this.optionsGruppen.sort((v1, v2) => {
                return v1 > v2
              })
              if (this.filter.Gruppen != undefined) {
                this.filter.Gruppen = this.filter.Gruppen.filter((a) => {
                  return this.optionsGruppen.includes(a)
                })
              }


              this.optionsProdukte = rv.AuswahlFilter.Produkte || []
              this.optionsProdukte.sort((v1, v2) => {
                return v1 > v2
              })
              if (this.filter.Produkte != undefined) {
                this.filter.Produkte = this.filter.Produkte.filter((a) => {
                  return this.optionsProdukte.includes(a)
                })
              }

              this.optionsZielversionen = rv.AuswahlFilter.Zielversionen || []
              this.optionsZielversionen.sort((v1, v2) => {
                return v1 > v2
              })
              if (this.filter.Zielversionen != undefined) {
                this.filter.Zielversionen = this.filter.Zielversionen.filter(
                  (a) => {
                    return this.optionsZielversionen.includes(a)
                  }
                )
              }

              // Eindeutigen Key spendieren, um Vuetify-Warnungen zu unterbinden
              let worte = this.suchtext.split(" ")
              this.entries.forEach(function (el, idx) {
                if (el.Text == null)
                  return
                el.key = idx
                worte.forEach(function (wort) {
                  let regex = new RegExp("(" + wort + ")", "gim")
                  el.Text = el.Text.replace(regex, "<mark>$1</mark>")
                })
              })
              this.isLoading--
            } else {
              console.error("VorgangSucheDurchfuehren", rv.Message)
              this.$event.$emit("global-snackbar", rv.Message)
              this.isLoading--
            }
          },
          (error) => {
            console.error("VorgangSucheDurchfuehren", error)
            this.$event.$emit("global-snackbar", error)
            this.isLoading--
          }
        )
    },
  },
  destroyed() {
    this.$event.$off("button", this.handleButtons)
  },

  created() {
    this.filter = new DtpFilter()
  },
  mounted() {
    this.$event.$on("button", this.handleButtons)
    this.filter = new DtpFilter()
    this.initializeFilter()
    let f = localStorage.getItem("SearchFilter")
    if (f != null && f != undefined && f != "undefined") {
      // DEBUG this.filter = JSON.parse(f)
    }

    let s = localStorage.getItem("SearchText")
    if (s != null && s != undefined && s != "undefined" && s != "null") {
      this.suchtext = s
    }
    this.$event.$emit("show-buttons", "E")

    if (this.suchtext.length > 0) {
      this.getDataFromApi()
    }
  },
  data() {
    return {
      datemenuVon: false,
      datemenuBis: false,

      entries: [],
      selected: [],
      suchtext: "",
      isLoading: 0,
      filter: new DtpFilter(),

      optionsTypen: [],
      optionsPrios: [],
      optionsStatus: [],
      optionsMelder: [],
      options: {
        sortBy: ["Nummer"],
        sortDesc: [true],
      },
      optionsVerantwortliche: [],
      optionsGruppen: [],
      optionsProdukte: [],
      optionsZielversionen: [],
      optionsMeldeversionen: [],
      headers: [
        { text: "Quelle", value: "Quelle", sortable: false },
        { text: "Status", value: "ZustandStatusShortId", sortable: false },
        { text: "Produkt", value: "ProduktName", sortable: false },
        { text: "Fundstelle", value: "Fundstelle", sortable: false },
      ],
    }
  },
})
