

























































import SprHeader from "../components/SprHeader.vue"
import SprAktion from "../components/SprAktion.vue"
import { GeoGlobals } from "../GeoGlobals"
import {
  DtoState,
  ConflictResolutionType,
  VorgangStatus,
  DtoResultState,
} from "../FacadeVorgangListe"
import Config from "../Config"
import { AsyncLocalStorage } from "async_hooks"
var htmlEncode = require("js-htmlencode")
declare const $geoGlobals: GeoGlobals
var cloneDeep = require("lodash.clonedeep")

import Vue from "vue"
//export default {
export default Vue.extend({
  beforeRouteLeave(to, from, next) {
    if (this.isModified && this.dialogPreventLeaving == false) {
      this.nextPage = to
      this.dialogPreventLeaving = true
      next(false)
    } else {
      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isModified && this.dialogPreventLeaving == false) {
      this.nextPage = to
      this.dialogPreventLeaving = true
      next(false)
    } else {
      this.updateCounter++
      next()
    }
  },
  destroyed() {
    this.$event.$off("button", this.handleButtons)
    this.$event.$off("produktChanged")
    this.$event.$off("vorgang-modified-changed")
    this.$event.$off("aktion-modified-changed")
  },
  created() {
    this.$event.$emit("show-buttons", "OASZ")
    this.$event.$on("button", this.handleButtons)
  },
  updated() {
    // Eventuelle Sprungmarken
    let matches = window.location.hash.match(/\?j=(.*)/)
    if (matches != null) {
      let dest = document.getElementById(matches[1])
      if (dest != null) {
        dest.scrollIntoView()
      }
    }
  },
  mounted() {
    this.$event.$on("produktChanged", (data) => {
      if (this.vorgang == null)
        return
      if (data == this.produkt)
        return

      this.produkt = data
      if (this.produkt == "" || this.produkt == null) {
        this.ErlaubteAktionen = []
        return
      }
      if (this.isNeu) {
        // Ich habe keinen gültigen Vorgang, den ich als Parameter für
        // VorgangRechteLaden brauche, also:
        return
      }
      // Wenn das Produkt sich geändert hat, muss die Meldeversion weg, sonst bekomme
      // ich gleich die Meldeversion zurück und mache daraus wieder das ALTE Produkt.

      // this.vorgang.MeldeversionRef.ShortGuid = null
      this.reloadRights()
    })

    this.$event.$on("aktion-modified-changed", (data) => {
      this.callIsModified()
    })

    this.$event.$on("vorgang-modified-changed", (data) => {
      this.callIsModified()
    })

    let secondStep = () => {
      let id = decodeURIComponent(this.$route.params.id)
      if (id == "AAAAAAAAAAAAAAAAAAAAAA") {
        this.$event.$emit("show-buttons", "OAS")
      } else this.$event.$emit("show-buttons", "OASZ")

      let post = this.GetDetails(id)
      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.processResult(result, false)
            this.vorgang.produktName = this.produkt
            this.$nextTick(() => {
              this.vorgang.Aktionen.forEach((aktion) => {
                let sg = aktion.ShortGuid
                let el = this.$refs[sg]
                const aktionModified = el[0].resetModified()
              })
              this.callIsModified()
            })
          } else {
            this.$event.$emit("global-snackbar", result.data.d.Message)
            console.error("mounted()/GetDetails", result.data.d.Message)
          }
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("mounted()/GetDetails()", error)
        }
      )
    }

    let postPre = this.GetDetailsDialog()
    postPre.then(
      (result) => {
        if (result.data.d.Status == DtoResultState.Ok) {
          this.facade = result.data.d
          this.facade.Institutionen.forEach((element) => {
            if (element.BundeslandRef == null) element.BundeslandRef = {}
          })
          secondStep()
        } else {
          this.$event.$emit("global-snackbar", result.data.d.Message)
          console.error("mounted()/GetDetailsDialog()", result.data.d.Message)
        }
      },
      (error) => {
        this.$event.$emit("global-snackbar", error)
        console.error("mounted()/GetDetails()", error)
      }
    )
  },
  computed: {
    isModified() {
      return this.callIsModified()
    },
    erlaubteAktionen() {
      let rv = this.ErlaubteAktionen

      const conf = new Config()

      rv = rv.filter((el) => {
        const aktion = conf.getAktion(el.ShortGuid)[2]
        return aktion != "Melden"
      })

      const ccc = this

      rv.sort(function (a, b) {
        let aa = ccc.ToVerb(a.ShortGuid)
        let bb = ccc.ToVerb(b.ShortGuid)
        return aa.localeCompare(bb)
      })
      return rv
    },
    AbschliessenEnabled(): string {
      var config = new Config()
      let abschliessenOderEinstellen = this.vorgang.Aktionen.find((el) => {
        let v1 = (el.State & DtoState.New) == DtoState.New
        let v2 =
          el.__type
            .substr(el.__type.lastIndexOf(".") + 1)
            .replace("Dto", "") === "Abschliessen"
        let v3 =
          el.__type
            .substr(el.__type.lastIndexOf(".") + 1)
            .replace("Dto", "") === "Einstellen"
        return v1 && (v2 || v3)
      })
      if (abschliessenOderEinstellen) {
        let abschliessen = this.vorgang.Aktionen.find((el) => {
          let v1 = (el.State & DtoState.New) == DtoState.New
          let v2 =
            el.__type
              .substr(el.__type.lastIndexOf(".") + 1)
              .replace("Dto", "") === "Abschliessen"
          return v1 && v2
        })
        let v = abschliessen
          ? config.getAktion("GeoBremen.LisaSpr.Abschliessen")
          : config.getAktion("GeoBremen.LisaSpr.Einstellen")
        return "Es gibt bereits eine neue <em>" + v[2] + "</em>-Aktion"
      }

      let bereitsAbgeschlossen =
        this.vorgang.Status == VorgangStatus.Abgeschlossen
      if (bereitsAbgeschlossen) {
        return (
          "Der Vorgang befindet sich bereits im Status <em>" +
          config.getVorgangStatus(VorgangStatus.Abgeschlossen)[1] +
          "</em>"
        )
      }
      return ""
    },
    EinstellenEnabled(): string {
      let abschliessenOderEinstellen = this.vorgang.Aktionen.find((el) => {
        let v1 = (el.State & DtoState.New) == DtoState.New
        let v2 =
          el.__type
            .substr(el.__type.lastIndexOf(".") + 1)
            .replace("Dto", "") === "Abschliessen"
        let v3 =
          el.__type
            .substr(el.__type.lastIndexOf(".") + 1)
            .replace("Dto", "") === "Einstellen"
        return v1 && (v2 || v3)
      })
      var config = new Config()
      if (abschliessenOderEinstellen) {
        let abschliessen = this.vorgang.Aktionen.find((el) => {
          let v1 = (el.State & DtoState.New) == DtoState.New
          let v2 =
            el.__type
              .substr(el.__type.lastIndexOf(".") + 1)
              .replace("Dto", "") === "Abschliessen"
          return v1 && v2
        })
        let v = abschliessen
          ? config.getAktion("GeoBremen.LisaSpr.Abschliessen")
          : config.getAktion("GeoBremen.LisaSpr.Einstellen")
        return "Es gibt bereits eine neue <em>" + v[2] + "</em>-Aktion"
      }
      let bereitsEingestellt = this.vorgang.Status == VorgangStatus.Eingestellt
      if (bereitsEingestellt) {
        return (
          "Der Vorgang befindet sich bereits im Status <em>" +
          config.getVorgangStatus(VorgangStatus.Eingestellt)[1] +
          "</em>"
        )
      }
      // return abschliessenOderEinstellen == undefined && !bereitsEingestellt;
      return ""
    },
    WeiterleitenEnabled(): string {
      // Nur eine neue Weiterleitung erlauben
      let found = this.vorgang.Aktionen.find((el) => {
        let v1 = (el.State & DtoState.New) == DtoState.New
        let v2 =
          el.__type
            .substr(el.__type.lastIndexOf(".") + 1)
            .replace("Dto", "") === "Weiterleiten"
        return v1 && v2
      })
      if (found == undefined) return ""
      else {
        var config = new Config()
        let v = config.getAktion("GeoBremen.LisaSpr.Weiterleiten")
        return "Es gibt bereits eine neue <em>" + v[2] + "</em>-Aktion"
      }
    },
  },
  watch: {
    $route() {
      let id = decodeURIComponent(this.$route.params.id)
      if (id == "AAAAAAAAAAAAAAAAAAAAAA") {
        this.$event.$emit("show-buttons", "OAS")
      } else this.$event.$emit("show-buttons", "OASZ")
      let post = this.GetDetails(id)
      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            this.vorgang = null
            this.$nextTick(() => {
              this.processResult(result, true)
              this.vorgang.produktName = this.produkt
              window.scrollTo(0, 0)


              this.callIsModified()
              this.$event.$emit("page-modified-changed", false)
              //this.$refs.completeForm.reset()
            })
          } else {
            this.$event.$emit("global-snackbar", result.data.d.Message)
            console.error("$route()", result.data.d.Message)
          }
        },
        (error) => {
          this.$event.$emit("global-snackbar", error)
          console.error("$route()", error)
        }
      )
    },
  },
  data() {
    return {
      sentDummyVersion: false,
      ErlaubteAktionen: [],
      dialogPreventLeaving: false,
      nextPage: "",
      pageModified: false,
      produkt: null,
      facade: null,
      vorgang: null,
      isNeu: false,
      aktionFacade: null,
      /*      spr: {
        Vorgang: {},
        Dialog: { prio: {}, typ: {}, meldeversion: {}, produkt: {} },
        meldung: "loading &hellip;"
      }*/
    }
  },
  components: {
    SprHeader,
    SprAktion,
  },

  methods: {
    reloadRights() {
      let post = this.GetRights()
      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            if (this.sentDummyVersion) {
              this.sentDummyVersion = false
            }
            let name = this.vorgang.produktName
            this.processResult(result, false)
            this.vorgang.produktName = name
          } else {
            this.$event.$emit("global-snackbar", result.data.d.Message)
            console.error("reloadRights()", result.data.d.Message)
          }
        },
        (error) => {
          console.error("reloadRights()", error)
        }
      )
    },
    clickValidate() {
      let rv = this.$refs.completeForm.validate()

      this.vorgang.Aktionen.forEach((aktion) => {
        let sg = aktion.ShortGuid
        let el = this.$refs[sg][0]


        // SPR 3602: Migrierte Fehler sollen nicht das Speichern behindern, wenn man sie nicht korrigieren kann.
        if ((aktion.State & DtoState.Edit) == DtoState.Edit) {
          let rva = el.validate()
          rv = rv && rva
        }
      })
      if (!rv) {
        this.$event.$emit(
          "global-snackbar",
          "Das Formular ist nicht korrekt gefüllt"
        )
      }
      return rv
    },
    callIsModified() {
      let vorgang = this.$refs.vorgang
      if (vorgang == undefined) return false
      let rv = false
      const vorgangModified = vorgang.vorgangModified // vorgang.isModified()
      rv = rv || vorgangModified

      this.vorgang.Aktionen.forEach((aktion) => {
        let sg = aktion.ShortGuid
        let el = this.$refs[sg]
        const aktionModified = el[0].isModified()
        rv = rv || aktionModified
      })
      //if(rv!=this.pageModified)
      {
        this.pageModified = rv
        this.$event.$emit("page-modified-changed", this.pageModified)
      }
      return rv
    },
    readFileAsync(aktion, file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          aktion.Base64Content = reader.result
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    ButtonEnabled(a): string {
      const conf = new Config()
      const aktion = conf.data.Aktionen.find((el) => {
        return el.ShortGuid == a.ShortGuid
      })
      if (aktion != null) {
        const lastIndex = aktion.Klasse.lastIndexOf(".")
        const shortName = aktion.Klasse.substr(lastIndex + 1).replace(
          "Dto",
          ""
        )
        switch (shortName) {
          case "Weiterleiten":
            return this.WeiterleitenEnabled
          case "Abschliessen":
            return this.AbschliessenEnabled
          case "Einstellen":
            return this.EinstellenEnabled
          default:
            return ""
        }
      }
      return ""
    },
    ToVerb: function (shortGuid: string): string {
      let conf = new Config()
      let xxx = conf.getAktion(shortGuid)
      return xxx[2]
    },
    ToShortClass: function (shortGuid: string): string {
      let conf = new Config()
      let xxx = conf.getAktion(shortGuid)
      return xxx[4]
    },

    handleButtons: function (data) {
      if (data == "okay") this.clickOk()
      else if (data == "abbrechen") this.clickCancel()
      else if (data == "speichern") this.clickSave()
      else if (data == "zurücksetzen") {
        this.clickReload()
      }
    },
    oneSelected: function () { },
    processResult: function (result, save: boolean = true) {
      this.ErlaubteAktionen = result.data.d.ErlaubteAktionen
      this.vorgang = result.data.d.Vorgang
      document.title = this.vorgang.Nummer.Value + "•" + $geoGlobals.Title
      if (this.vorgang.InteressierteRefs == null) {
        this.vorgang.InteressierteRefs = {}
      }

      if (this.vorgang.InteressierteRefs.List == null) {
        this.vorgang.InteressierteRefs.List = []
      }

      if (this.vorgang.ZielversionRef == null) this.vorgang.ZielversionRef = {}
      if (this.vorgang.SchlagwortArtenRefs == null)
        this.vorgang.SchlagwortArtenRefs = []


      if (this.vorgang.MeldeversionRef == null) {
        this.vorgang.MeldeversionRef = {}
      } else {
        if (this.produkt == null || true) {
          let xxx = this.facade.Produktversionen.find(
            (el) => el.ShortGuid == this.vorgang.MeldeversionRef.ShortGuid
          )
          if (xxx != undefined) {
            let produktId = xxx.ProduktRef.ShortGuid
            let produkt = this.facade.Produkte.find(
              (el) => el.ShortGuid == produktId
            )
            let gruppeId = produkt.GruppeRef.ShortGuid
            this.produkt = produktId
          }
        }
      }
      // DateTime korrigieren
      for (let aktion in this.vorgang.Aktionen) {
        let a = this.vorgang.Aktionen[aktion]
        let matches = a.Zeitpunkt.match(/(\d+)/)
        a.Zeitpunkt = new Date(parseInt(matches[0]))
        if (a.Wiedervorlage != null) {
          matches = a.Wiedervorlage.match(/(\d+)/)
          a.Wiedervorlage = new Date(parseInt(matches[0]))
        }
        if (a.StellvertreterRef == null) {
          a.StellvertreterRef = {}
        }
      }

      if (save) {
        this.$nextTick(() => {
          if (this.$refs.vorgang) this.$refs.vorgang.storeCurrentState()
        })
      }
    },
    newAction: function (method: string) {
      // DtoVorgang und DtoProdukt als Parameter
      let p
      if (
        this.vorgang.MeldeversionRef == null ||
        this.vorgang.MeldeversionRef.ShortGuid == null
      ) {
        let pi = this.produkt
        p = this.facade.Produkte.find((el) => el.ShortGuid == pi)
      } else {
        let vi = this.vorgang.MeldeversionRef.ShortGuid
        let v = this.facade.Produktversionen.find((el) => el.ShortGuid == vi)
        let pi = v.ProduktRef.ShortGuid
        p = this.facade.Produkte.find((el) => el.ShortGuid == pi)
      }
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }

      let post = this.$http.post(
        $geoGlobals.ServerAddress + method,
        {
          dtoVorgangRef: { ShortGuid: this.vorgang.ShortGuid },
          dtoProduktRef: { ShortGuid: p.ShortGuid }
        },
        auth
      )
      post.then(
        (result) => {
          this.aktionFacade = result.data.d
          if (this.aktionFacade.FolgestatusRefs == null) {
            this.aktionFacade.FolgestatusRefs = []
          }
          let aktion = this.aktionFacade.Aktion
          if (this.aktionFacade.ProduktAkteure != null) {
            aktion.ProduktAkteure = this.aktionFacade.ProduktAkteure
          }

          let matches = aktion.Zeitpunkt.match(/(\d+)/)
          aktion.Zeitpunkt = new Date(parseInt(matches[0]))

          if (aktion.EmpfaengerRef === null) {
            aktion.EmpfaengerRef = {}
          }
          if (aktion.AnzeigeOriginal === null) {
            aktion.AnzeigeOriginal = {}
          }
          aktion.State = DtoState.New
          if (method.includes("Duplizieren")) {
            aktion.Zielversion = {}
            aktion.Zielversion.State = DtoState.None
            aktion.Zielversion.ShortGuid = null

            aktion.produkt = this.produkt
          }
          this.vorgang.Aktionen.push(aktion)

        },
        (error) => {
          console.error(method, error)
        }
      )
    },
    click: function (param) {
      this.newAction("Aktion" + param + "Neu")
    },
    clickWeiterleiten: function () {
      this.newAction("AktionWeiterleitenNeu")
    },
    clickKommentieren: function () {
      this.newAction("AktionKommentierenNeu")
    },
    clickAbschliessen: function () {
      this.newAction("AktionAbschliessenNeu")
    },
    clickEinstellen: function () {
      this.newAction("AktionEinstellenNeu")
    },
    clickDuplizieren: function () {
      this.newAction("AktionDuplizierenNeu")
    },
    clickPausieren: function () {
      this.newAction("AktionPausierenNeu")
    },
    clickDokumentieren: function () {
      this.newAction("AktionDokumentierenNeu")
    },
    clickKennzeichnen: function () {
      this.newAction("AktionKennzeichnenNeu")
    },
    clickReferenzieren: function () {
      this.newAction("AktionReferenzierenNeu")
    },
    clickSave: function () {
      let valid = this.clickValidate()
      if (!valid) return
      this.SetDetails(() => {
      })
    },
    clickCancel: function () {
      this.$router.push("/i")
    },
    clickOk: function () {
      let valid = this.clickValidate()
      if (!valid) return
      this.SetDetails(
        () => {
          this.$router.push("/i/list")
        },
        (error) => {
          console.error("clickOk()", error)
        }
      )
    },

    clickReload: function () {
      for (let i = 0; i < 2; i++) {
        let id = decodeURIComponent(this.$route.params.id)
        let post = this.GetDetails(id)
        post.then(
          (result: any) => {
            this.processResult(result, true)
            this.vorgang.produktName = this.produkt
            this.$nextTick(() => {
              this.vorgang.Aktionen.forEach((aktion) => {
                let sg = aktion.ShortGuid
                let el = this.$refs[sg]
                const aktionModified = el[0].resetModified()
              })
              this.callIsModified()
            })
          },
          (error: any) => {
            console.error("clickReload()", error)

          }
        )
      }
    },

    GetDetailsDialog: function () {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      let post

      post = this.$http.post(
        $geoGlobals.ServerAddress + "VorgangDialogLaden",
        {},
        auth
      )
      return post
    },
    GetRights: function () {
      if (this.vorgang == null) {
        console.error("Why is this called anyway?")
        return
      }
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      const copy = cloneDeep(this.vorgang)

      // Ohne Version kann ich das Produkt nicht an den Server melden!
      if (this.produkt != null) {
        if (this.vorgang.MeldeversionRef == null || this.vorgang.MeldeversionRef.ShortGuid == null) {
          let values: [] = this.facade.Produktversionen
          let ffilter = this.produkt
          if (values === undefined || ffilter === undefined) return undefined

          let rvv: any = values.filter((a: any) => {
            return a.ProduktRef.ShortGuid === ffilter
          })
          // Turn Version into VersionRef
          copy.MeldeversionRef = {
            State: DtoState.None,
            ShortGuid: rvv[0].ShortGuid
          }
          this.sentDummyVersion = true
        }
      }

      let post = this.$http.post(
        $geoGlobals.ServerAddress + "VorgangRechteAktualisiern",
        {
          dtoVorgang: copy,
        },
        auth
      )
      return post
    },

    GetDetails: function (id: string) {
      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      let post
      if (id == "AAAAAAAAAAAAAAAAAAAAAA") {
        this.produkt = null
        this.isNeu = true
        post = this.$http.post(
          $geoGlobals.ServerAddress + "VorgangNeu",
          {},
          auth
        )

        return post
      } else {
        this.isNeu = false
        post = this.$http.post(
          $geoGlobals.ServerAddress + "VorgangLaden",
          { dtoVorgangRef: { ShortGuid: id } },
          auth
        )
        return post
      }
    },

    SetDetails: function (success?: Function) {
      let auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let vorgangToSave = this.vorgang
      vorgangToSave.Aktionen.forEach(aktion => {
        let el = this.$refs[aktion.ShortGuid]
        if (el) {
          if (el[0].$refs.theEditor != undefined) {
            if (!el[0].$refs.theEditor.instance.checkDirty()) {
              aktion.Beschreibung = JSON.parse(el[0]._data.aktionAsLoaded).Beschreibung
            }
          }
        }
      })

      if (vorgangToSave.SchlagwortArtenRefs.List != null) {
        vorgangToSave.SchlagwortArtenRefs.List.forEach(el => {
          if (typeof el == "string") {
            let neu = {
              ShortGuid: el,
              State: DtoState.None
            }
            vorgangToSave.SchlagwortArtenRefs.List = vorgangToSave.SchlagwortArtenRefs.List.filter(a => a != el)
            vorgangToSave.SchlagwortArtenRefs.List.push(neu)
          }
        })
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "VorgangDialogSpeichern",
        {
          dtoVorgang: vorgangToSave,
          conflictResolution: ConflictResolutionType.Overwrite,
        },
        auth
      )
      post.then(
        (result) => {
          if (result.data.d.Status == DtoResultState.Ok) {
            //
            // Rückgabe-Wert wie bei GetDetails
            this.processResult(result, true)
            this.vorgang.produktName = this.produkt
            this.$nextTick(() => {
              this.vorgang.Aktionen.forEach((aktion) => {
                let sg = aktion.ShortGuid
                let el = this.$refs[sg]
                if (el) {
                  const aktionModified = el[0].resetModified()
                }
              })
              this.callIsModified()
              this.isNeu = false
              this.$event.$emit("show-buttons", "OASZ")
              this.reloadRights()
              success()
            })

            // Eventuell Dateien hochladen
            let files = []
            let guids = []
            for (let ref in this.$refs) {
              let el = this.$refs[ref]
              if (Array.isArray(el) && el.length > 0) {
                el = el[0]
                let a = el._props.aktion
                if ((a.State & DtoState.New) === 0)
                  continue
                let data = el._data
                if (data.file != null) {
                  guids.push({ ShortGuid: a.ShortGuid })
                  let thisFile = data.file
                  let sr = files.find((f) => {
                    return (
                      f.name == thisFile.name &&
                      f.lastModified == thisFile.lastModified &&
                      f.size == thisFile.size &&
                      f.type == thisFile.type
                    )
                  })
                  if (sr == undefined) {
                    files.push(thisFile)
                  }
                }
              }
            }
            if (files.length > 0) {
              let formData = new FormData()
              formData.append("dtoDokumentierenRefs", JSON.stringify(guids))
              formData.append(
                "dtoVorgangRef",
                JSON.stringify({ ShortGuid: this.vorgang.ShortGuid })
              )
              formData.append(
                "conflictResolution",
                JSON.stringify(ConflictResolutionType.Overwrite)
              )
              for (var i = 0; i < files.length; i++) {
                let file = files[i]
                formData.append("files[" + i + "]", file)
              }

              const auth2 = {
                headers: {
                  Authorization: "Bearer " + $geoGlobals.jwtoken,
                  "Content-Type": "multipart/form-data",
                },
              }

              let post = this.$http.post(
                $geoGlobals.ServerAddress +
                "VorgangDialogDokumenteSpeichernJson",
                formData,
                auth2
              )
              post.then(
                (result) => {
                  let parser = new DOMParser()
                  result = parser.parseFromString(result.data, "text/xml")
                  let temp = result.documentElement.children
                  let status = temp[0].innerHTML
                  let message = ""
                  if (temp[1] != undefined) {
                    message = temp[1].innerHTML
                  }
                  if (status === "Error") {
                    this.$event.$emit("global-snackbar", message)
                    console.error("VorgangDialogDokumenteSpeichernJson", message)
                  }
                },
                (error) => {
                  console.error("VorgangDialogDokumenteSpeichernJson", error)
                  this.$event.$emit("global-snackbar", error)
                }
              )
            } else {
              //setTimeout(() =>{success()},10)
            }
          } else {
            // Some problem with saving

            console.error("VorgangDialogSpeichern", result.data.d.Message)
            this.$event.$emit("global-snackbar", result.data.d.Message)
          }
        },
        (error) => {
          console.error("VorgangDialogSpeichern", error)
        }
      )
    },
  },
})
