


























































































































import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
import PersonNeuEdit from "../components/PersonNeuEdit.vue"

import Vue from "vue"
import { Zugriffsrecht } from "../FacadeVorgangListe"

export default Vue.extend({
  components: { PersonNeuEdit },
  name: "App",
  updated() {
    let newValue = $geoGlobals.jwtoken != null
    if (this.loggedIn != newValue) {
    }
    this.loggedIn = newValue

  },
  mounted() {
    this.loggedIn = false
    this.checkTestumgebung()


    this.$event.$on("show-buttons", (data) => {
      this.exportButtonVisible = data.includes("E")
      this.okayButtonVisible = data.includes("O")
      this.abbrechenButtonVisible = data.includes("A")
      this.speichernButtonVisible = data.includes("S")
      this.zurücksetzenButtonVisible = data.includes("Z")
    })

    this.$event.$on("page-modified-changed", (data) => {
      this.saveEnabled = data
    })

    this.$event.$on("global-snackbar", (data) => {
      this.text = data
      this.snackbar = true
    })
    this.$event.$on("update-logout-button", (data) => {
      this.loggedIn = $geoGlobals.jwtoken != null
      if (!this.loggedIn) {
        this.showMenu = false
      } else {
        let token = $geoGlobals.Token
        if (token.Rights.length > 0) {
          this.showMenu = true
        }
        // TODO Das ist nicht schön
        this.enablePeople = this.showMenu
        this.enableProducts = token.Rights.includes(
          Zugriffsrecht.ProdukteVerwalten
        )
        this.enableGroups = token.Rights.includes(
          Zugriffsrecht.GruppenVerwalten
        )
        this.enableConfig = token.Rights.includes(
          Zugriffsrecht.KonfiguratonVerwalten
        )
      }
    })

    let ppp = $geoGlobals.Myself
  },
  data() {
    return {
      appName: "LISA SPR 2",
      test: false,
      showMenu: false,
      enablePersons: false,
      enableProducts: false,
      enablePeople: false,
      enableGroups: false,
      enableConfig: false,
      saveEnabled: false,
      goto: null,
      loggedIn: true,
      workPageValue: null,
      snackbar: false,
      dialog: false,
      exportButtonVisible: false,
      okayButtonVisible: true,
      abbrechenButtonVisible: true,
      speichernButtonVisible: true,
      zurücksetzenButtonVisible: true,
      text: "",
    }
  },
  watch: {
    $route() {
      document.title = $geoGlobals.Title
      let url = window.location.hash
      if (
        url.endsWith("list") ||
        url.includes("/spr") ||
        url.includes("search") ||
        url.includes("config")
      ) {
        this.workPageValue = true
        return
      }
      this.workPageValue = false
    },
  },
  computed: {
    devmode: function () {
      return process.env.NODE_ENV === "development"
    },
    workPage: function () {
      if (this.workPageValue == null) {
        let url = window.location.hash
        if (
          url.endsWith("list") ||
          url.includes("/spr") ||
          url.includes("search")
        ) {
          this.workPageValue = true
        }
      }
      if (this.workPageValue == null) {
        this.workPageValue = false
      }
      return this.workPageValue
    },
  },
  methods: {
    checkTestumgebung: function () {
      var xhttp = new XMLHttpRequest()
      xhttp.open("GET", "./TESTUMGEBUNG.txt")
      let vue = this
      xhttp.onreadystatechange = function () {
        if (xhttp.readyState === 4) {
          if (xhttp.status == 200) {
            if (xhttp.response.includes('<!DOCTYPE html>')) {
              console.info("keep off Testumgebung")
              return
            } else {
              console.info("turn on Testumgebung")
              vue.appName = xhttp.response
              vue.test = true
            }
          }
        }
      }
      xhttp.send()
    },
    gotoSpr: function () {
      let sprNr: number = parseInt(this.goto)

      if (isNaN(sprNr)) {
        this.$event.$emit(
          "global-snackbar",
          "Geben Sie bitte eine Vorgangsnummer ein"
        )
        return
      }

      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }
      this.$http
        .post(
          $geoGlobals.ServerAddress + "VorgangRefLaden",
          { nummer: sprNr },
          auth
        )
        .then(
          (result) => {
            const rv = result.data.d
            if (rv.Status == 1)
              this.$router.push(
                "/i/spr/" + encodeURIComponent(rv.Dto.ShortGuid)
              )
            else {
              this.$event.$emit("global-snackbar", rv.Message)
            }
          },
          (error) => {
            this.$event.$emit("global-snackbar", error)
            console.error(error)
          }
        )
    },
    send: function (data) {
      this.$event.$emit("button", data)
    },
    logout: function () {
      /*
      clearTimeout($geoGlobals.refreshTimer)
      delete $geoGlobals.refreshTimer

      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken }
      };
      this.$http
        .post(
          $geoGlobals.ServerAddress + "InvalidateRefreshTokens",
          { responseType: "json" },
          auth
        )

      delete $geoGlobals.jwtoken
      */

      this.showMenu = false
      this.$router.push("/login")
    },
    GetHelloWorld: function () {
      this.$http
        .post($geoGlobals.ServerAddress + "HelloWorld", {
          responseType: "json",
        })
        .then(
          (result) => {
            alert(result.data.d.Message)
          },
          (error) => {
            alert("ERROR (" + error + ")")
            console.error("Err", error)
          }
        )
    },
  },
})
