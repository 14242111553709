const jwt = require("jsonwebtoken")

export class GeoGlobals {
    constructor() { }
    private serverAddress = ""
    jwtoken: string
    refreshTimer: NodeJS.Timeout
    destination: string
    configData: any | undefined
    showAddImage: (any) => void

    get ServerAddress(): string {
        if (process.env.NODE_ENV === 'development') {
            // Pointing to servcer running in Visual Studio
            this.serverAddress = "http://localhost:49291/LisaSprService.asmx/"
        } else {
            // Otherwise assume that the server is running at the client's origin
            // if (this.serverAddress==null || this.serverAddress.length===0){
            let vorne = window.location.href.substring(0, window.location.href.indexOf("#"))
            let rv = vorne + "LisaSprService.asmx/"
            return rv
        }
        return this.serverAddress
    }

    get Token(): any {
        const decoded = jwt.decode(this.jwtoken)
        if (decoded == null)
            return null
        return decoded
    }

    get Title(): string {
        return "LISA SPR 2.0.3PL2"
    }

    get Myself(): any {
        const decoded = jwt.decode(this.jwtoken)
        if (decoded == null)
            return null
        return { Name: decoded.Name, ShortGuid: decoded.Sub }
    }

}

