































































































































































import { GeoGlobals } from "../GeoGlobals"
import Config from "../Config"
import {
  DtpFilter,
  DtpSeite,
  DtpSortierung,
  DtpInfo,
  DtoSortDirection,
  DtoExportTyp,
  DtoState,
  DtoResultState, DtpDatumBereich, DtoExportInfo, DtoExportColumn
} from "../FacadeVorgangListe"
import Vue from "vue"
declare const $geoGlobals: GeoGlobals

export default Vue.extend({
  destroyed() {
    this.$event.$off("button", this.handleButtons)
  },
  mounted() {
    this.$event.$on("button", this.handleButtons)
  },
  props: ["modus", "excludes"],
  data() {
    return {
      datemenuVon: false,
      datemenuBis: false,
      selected: [],
      filter: new DtpFilter,
      isLoading: false,
      optionsTypen: [],
      requestCounter: 0,
      optionsPrios: [],
      optionsStatus: [],
      optionsMelder: [],
      optionsVerantwortliche: [],
      optionsProdukte: [],
      optionsGruppen: [],
      optionsZielversionen: [],
      optionsMeldeversionen: [],
      options: {
        sortBy: ["Nummer"],
        sortDesc: [true],
      },
      entries: [],
      totalEntries: 0,
      headers: [
        { text: "Nummer", value: "Nummer" },
        { text: "Typ", value: "Typ" },
        { text: "Prio", value: "Prio" },
        { text: "Status", value: "Status" },
        { text: "Melde&shy;datum", value: "Meldedatum" },
        { text: "Melder", value: "Melder" },
        { text: "Letzte WL", value: "LetzteWl" },
        { text: "Ver&shy;ant&shy;wort&shy;licher", value: "Verantwortlich" },
        { text: "Produkt", value: "Produkt" },
        { text: "Meldung", value: "Meldung", sortable: false },
        { text: "Ziel&shy;version", value: "Zielversion" },
      ],
    }
  },
  created() {
    this.filter = new DtpFilter()
    let f = localStorage.getItem("OverviewFilter" + this.modus)
    if (f != null) {
      this.filter = JSON.parse(f)
    }

    let o = localStorage.getItem("OverviewOptions" + this.modus)
    if (o != null) {
      this.options = JSON.parse(o)
    }
  },
  watch: {
    "filter.MeldedatumBereich": {
      handler(newV, oldV) {
        if (this.filter.MeldedatumBereich == null) {
          this.filter.MeldedatumBereich = new DtpDatumBereich()
        }
        const vorne = this.filter.MeldedatumBereich.From
        const hinten = this.filter.MeldedatumBereich.To
        if (vorne != null && hinten != null) {
          if (vorne > hinten) {
            this.filter.MeldedatumBereich.From = hinten
            this.filter.MeldedatumBereich.To = vorne
          }
        }
      },
      deep: true
    },
    filter: {
      handler(newV, oldV) {
        // this.getDataFromApi();
      },
      deep: true,
    },
    options: {
      handler(newV, oldV) {
        if (newV.sortBy == "Nummer") {
          this.rememberNummerSorting = newV.sortDesc[0]
        }
        // Wenn es gar keine Sortierung gibt, nach Nr sortieren, und zwar bevorzugt absteigend,
        // wenn nicht das gerade der vorherige Zustand war.
        if (this.options.sortBy.length == 0) {
          if (oldV.sortBy[0] !== "Nummer") {
            this.options.sortBy = ["Nummer"]
            this.options.sortDesc = [this.rememberNummerSorting]
          } else {
            this.options.sortDesc = [!this.rememberNummerSorting]
            this.options.sortBy = ["Nummer"]
          }
        } else {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },

  computed: {
    readableVon() {
      if (this.filter.MeldedatumBereich.From == null || this.filter.MeldedatumBereich.From == "")
        return null
      if (typeof this.filter.MeldedatumBereich.From == "string") {
        let v = new Date(this.filter.MeldedatumBereich.From)
        return this.ToDateText(v)
      }

      return this.ToDateText(this.filter.MeldedatumBereich.From)
    },

    readableBis() {
      if (this.filter.MeldedatumBereich.To == null || this.filter.MeldedatumBereich.To == "")
        return null
      if (typeof this.filter.MeldedatumBereich.To == "string") {
        let v = new Date(this.filter.MeldedatumBereich.To)
        return this.ToDateText(v)
      }

      return this.ToDateText(this.filter.MeldedatumBereich.To)
    },
  },
  methods: {
    clickClearVon(): void {
      this.filter.MeldedatumBereich.From = null
    },
    clickClearBis(): void {
      this.filter.MeldedatumBereich.To = null
    },
    ToDateText(date): string {

      if (typeof date == "string") {
        let matches = date.match(/(\d+)/)
        date = new Date(parseInt(matches[0]))
      }

      let rv = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      return rv
    },
    handleButtons: function (data) {
      if (data == "export") {
        let exinfo = new DtoExportInfo()

        this.$http
          .post(
            $geoGlobals.ServerAddress + "VorgangListeExportieren",
            {
              dtoFilter: this.filter,
              dtoSuchausdruck: { Suchworte: [] },
              dtoExportInfo: exinfo
            },
            {
              headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
            }
          )
          .then(
            (result) => {
              result = result.data.d
              if (result.Status == DtoResultState.Ok) {
                const url = $geoGlobals.ServerAddress.replace(
                  "LisaSprService.asmx/",
                  "LisaSprExport.ashx?fileName=" +
                  encodeURIComponent(result.ExportDateiname)
                )
                window.open(url, "_blank")
              } else {
                this.$event.$emit("global-snackbar", result.Message)
                console.error("VorgangListeExportieren", result.Message)
              }
            },
            (error) => {
              console.error("VorgangListeExportieren", error)
            }
          )
      } else {
        console.error("ListeControl received ", data)
      }
    },
    reload: function () {
      this.getDataFromApi()
    },
    resetFilters: function () {
      this.filter = new DtpFilter()
      this.getDataFromApi()
    },

    toDate(d: string): string {
      if (d === null) return ""
      let matches = d.match(/(\d+)/)
      let date = new Date(parseInt(matches[0]))
      return (
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        (date.getFullYear() - 2000)
      )
    },
    shortStatus(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangStatus(shortId)
      return rv[0]
    },
    shortPriorität(shortId: number) {
      const config = new Config()
      const prio = config.getVorgangPriorität(shortId)
      return prio[0]
    },
    longPriorität(shortId: number) {
      const config = new Config()
      const prio = config.getVorgangPriorität(shortId)
      return prio[1]
    },
    shortTyp(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangTyp(shortId)
      return rv[0]
    },
    longTyp(shortId: number) {
      const config = new Config()
      const rv = config.getVorgangTyp(shortId)
      return rv[1]
    },
    getDataFromApi: function () {
      this.isLoading = true
      if (sessionStorage.getItem("config") === null) {
        setTimeout(this.getDataFromApi, 100)
        return
      }

      const auth = {
        headers: { Authorization: "Bearer " + $geoGlobals.jwtoken },
      }

      let seite = new DtpSeite()
      seite.Seite = this.options.page
      this.requestCounter++
      seite.Anforderung = this.requestCounter
      seite.Anzahl = this.options.itemsPerPage

      let sortierung = new DtpSortierung()
      if (this.options.sortBy.length > 0) {
        this.options.sortBy.map((el, idx) => {
          let pair = { col: el, desc: this.options.sortDesc[idx] }
          const s = new DtpInfo()
          s.Direction = pair.desc
            ? DtoSortDirection.Descendung
            : DtoSortDirection.Ascending
          s.Order = idx
          sortierung[pair.col] = s
        })
      }

      localStorage.setItem(
        "OverviewOptions" + this.modus,
        JSON.stringify(this.options)
      )
      localStorage.setItem(
        "OverviewFilter" + this.modus,
        JSON.stringify(this.filter)
      )
      /*
      if(this.excludes!==undefined){
        this.excludes.forEach(element => {
          element.__type = "GeoBremen.LisaSpr.DtoRef"
        });
        this.filter.ExcludeVorgaenge = this.excludes
      }
      */
      if (this.modus == 0) this.filter.ExcludeVorgaenge = []

      let xxxFilter = this.filter

      let post
      if (this.modus == 2) {
        post = this.$http.post(
          $geoGlobals.ServerAddress + "VorgangListeLaden",
          {
            dtoSeite: seite,
            dtoFilter: xxxFilter,
            dtoSortierung: sortierung,
          },
          auth
        )
      } else {
        post = this.$http.post(
          $geoGlobals.ServerAddress + "VorgangListeLaden",
          {
            dtoSeite: seite,
            dtoFilter: this.filter,
            dtoSortierung: sortierung,
          },
          auth
        )
      }

      post.then(
        (result) => {
          const rv = result.data.d
          if (rv.Status == DtoResultState.Ok) {
            if (rv.Anforderung !== this.requestCounter) {
              console.info(
                "Response (" +
                rv.Anforderung +
                ") verworfen, warte auf (" +
                this.requestCounter +
                ")"
              )
              return
            }
            this.liste = rv
            this.entries = rv.Liste
            this.totalEntries = rv.Gesamtanzahl
            const config = new Config()
            this.optionsTypen = []
            if (rv.AuswahlFilter.Typen != null) {
              rv.AuswahlFilter.Typen.forEach((typ) => {
                let entry: any = {}
                entry.id = typ
                const p = config.getVorgangTyp(typ)
                entry.label = p[1] + " (" + p[0] + ")"
                this.optionsTypen.push(entry)
              })
              this.optionsTypen.sort((v1, v2) => {
                return v1.label > v2.label
              })
            }

            this.optionsPrios = []
            if (rv.AuswahlFilter.Prios != null) {
              rv.AuswahlFilter.Prios.forEach((typ) => {
                let entry: any = {}
                entry.id = typ
                const p = config.getVorgangPriorität(typ)
                entry.label = p[1] + " (" + p[0] + ")"
                this.optionsPrios.push(entry)
              })
              this.optionsPrios.sort((v1, v2) => {
                return v1.label > v2.label
              })
            }

            this.optionsStatus = []
            if (rv.AuswahlFilter.Status != null) {
              rv.AuswahlFilter.Status.forEach((typ) => {
                let entry: any = {}
                entry.id = typ
                const p = config.getVorgangStatus(typ)
                entry.label = p[1] + " (" + p[0] + ")"
                this.optionsStatus.push(entry)
              })
              this.optionsStatus.sort((v1, v2) => {
                return v1.label > v2.label
              })
            }
            this.optionsMelder = rv.AuswahlFilter.Melder || []
            this.optionsMelder.sort((v1, v2) => {
              return v1 > v2
            })

            this.optionsVerantwortliche =
              rv.AuswahlFilter.Verantwortliche || []
            this.optionsVerantwortliche.sort((v1, v2) => {
              return v1 > v2
            })

            this.optionsProdukte = rv.AuswahlFilter.Produkte || []
            this.optionsProdukte.sort((v1, v2) => {
              return v1 > v2
            })

            this.optionsGruppen = rv.AuswahlFilter.Gruppen || []
            this.optionsGruppen.sort((v1, v2) => {
              return v1 > v2
            })

            this.optionsZielversionen = rv.AuswahlFilter.Zielversionen || []
            this.optionsZielversionen.sort((v1, v2) => {
              return v1 > v2
            })
            this.optionsZielversionen = this.optionsZielversionen.map(a => {
              let n = {
                wert: a
              }
              return n
            })

            this.optionsMeldeversionen = rv.AuswahlFilter.Meldeversionen || []
            this.optionsMeldeversionen.sort((v1, v2) => {
              return v1 > v2
            })

            this.isLoading = false
          } else {
            console.error("VorgangListeLaden", rv.Message)
            this.$event.$emit("global-snackbar", rv.Message)
            this.isLoading = false
          }
        },
        (error) => {
          if (error.response?.status == 500) {
            // Vielleicht ist ein geladener Filter buggy
            localStorage.removeItem("OverviewFilter" + this.modus)
            this.filter = new DtpFilter()
          }
          console.error("VorgangListeLaden", error)
          this.$event.$emit("global-snackbar", error)
          this.isLoading = false
        }
      )
    },
  },
})
