






























































































































































































import Vue from "vue"
import {
  ConflictResolutionType,
  DtoResultState,
  DtoState,
} from "../FacadeVorgangListe"
import { GeoGlobals } from "../GeoGlobals"
declare const $geoGlobals: GeoGlobals
import Config from "../Config"
const config = new Config()


export default Vue.extend({
  mounted() {
    this.GetList()
    this.$event.$emit("show-buttons", "SZ")
    this.$event.$on("button", this.handleButtons)
  },

  destroyed() {
    this.$event.$off("button")
  },

  watch: {
    "facade.Config": {
      handler(newV, oldV) {
        const currentConfig = JSON.stringify(this.facade.Config)
        this.$event.$emit(
          "page-modified-changed",
          !(currentConfig === this.configAsLoaded)
        )
      },
      deep: true,
    },
  },

  computed: {
    allPrios: function () {
      return this.facade.Config.VorgangPrios
    },
    allVorgangstatus: function () {
      return this.facade.Config.VorgangStatus
    },
    allVorgangtyp: function () {
      return this.facade.Config.VorgangTypen
    },
    allZustandstatus: function () {
      return this.facade.Config.ZustandStatus
    },
  },

  methods: {
    handleButtons: function (data) {
      if (data === "zurücksetzen") {
        this.GetList()
      } else if (data === "speichern") {
        // Validate
        // keine leeren String
        const lists = [
          this.facade.Config.VorgangPrios,
          this.facade.Config.VorgangStatus,
          this.facade.Config.VorgangTypen,
          this.facade.Config.ZustandStatus,
        ]

        lists.forEach((liste) => {
          liste.forEach((element) => {
            if (
              element.Name == null ||
              element.Name.length == 0
              // || element.Kuerzel== null || element.Kuerzel.length==0
            ) {
              this.$event.$emit(
                "global-snackbar",
                "Alle Konfigurationswerte müssen einen Namen haben."
              )
              return
            }
          })
        })

        const auth = {
          headers: {
            Authorization: "Bearer " + $geoGlobals.jwtoken,
          },
        }
        let post = this.$http.post(
          $geoGlobals.ServerAddress + "ConfigVerwaltenDialogSpeichern",
          {
            dtoConfigDlg: this.facade.Config,
            conflictResolution: ConflictResolutionType.Overwrite,
          },
          auth
        )

        post.then(
          (result) => {
            result = result.data.d
            if (result.Status == DtoResultState.Ok) {
              // Config neu laden
              let postLoad = this.$http.post(
                $geoGlobals.ServerAddress + "ConfigCacheLaden",
                { currentHashCode: -1 },
                auth
              )
              postLoad.then(
                (result) => {
                  result = result.data.d
                  $geoGlobals.configData = result.Config
                },
                (error) => {
                  console.error("ConfigCacheLaden", result.Message)
                  this.$event.$emit("global-snackbar", result.Message)
                }
              )

              this.$event.$emit("page-modified-changed", false)
            } else {
              console.error("ConfigVerwaltenDialogSpeichern", result.Message)
              this.$event.$emit("global-snackbar", result.Message)
            }
          },
          (error) => {
            console.error("ConfigVerwaltenDialogSpeichern", error)
            this.$event.$emit("global-snackbar", error)
          }
        )
      } else {
        console.error("handleButtons()", `cannot handle '${data}'`)
      }
    },
    GetList: function () {
      const auth = {
        headers: {
          Authorization: "Bearer " + $geoGlobals.jwtoken,
        },
      }
      let post = this.$http.post(
        $geoGlobals.ServerAddress + "ConfigVerwaltenDialogLaden",
        {},
        auth
      )

      post.then(
        (result) => {
          result = result.data.d
          if (result.Status == DtoResultState.Ok) {
            this.facade = result
            this.configAsLoaded = JSON.stringify(this.facade.Config)
            this.$event.$emit("page-modified-changed", true)
          } else {
            console.error("GetList()", result.Message)
            this.$event.$emit("global-snackbar", result.Message)
          }
        },
        (error) => {
          console.error("GetList()", error)
          this.$event.$emit("global-snackbar", error)
        }
      )
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Eingabe notwendig",
      },
      facade: null,
      configAsLoaded: "",
      prioHeaders: [
        {
          text: "Name",
          align: "start",
          value: "Name",
        },
        {
          text: "Beschreibung",
          value: "Beschreibung",
          align: "start",
        },
        {
          text: "Kürzel",
          align: "start",
          value: "Kuerzel",
        },
      ],
    }
  },
});
